import { UserWithPermissions } from '@/api/transforms/auth/getAuthorizedUserTransform';
import { createContext } from 'react';
interface AuthContextType {
  userData: UserWithPermissions | null;
  isLoading: boolean;
}

const AuthProviderContext = createContext<AuthContextType>({
  userData: null,
  isLoading: true,
});

export default AuthProviderContext;
