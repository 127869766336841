import { useAppSelector, useAppDispatch } from '@/hooks';
import {
  selectQuestionValue,
  removeSuggestedQuestionValues,
} from '@/store/slices/questions';
import { QuestionAnswerValue, QuestionOptions } from '@/store/types/question';
import { QuestionId } from '@/store/types/basicTypes';
import {
  AiIndicator,
  FormControlLabelWrapper,
  RadioGroupWrapper,
} from './styled';
import {
  FormControlLabel,
  Radio,
  RadioGroup as MUIRadioGroup,
} from '@mui/material';
import Select from '../Select/Select';

interface RadioProps {
  options: QuestionOptions[];
  onChange: (val: string) => void;
  questionId: QuestionId;
  'data-testid': string;
  controlledValue?: string;
  onClear?: () => void;
  suggestedValue?: QuestionAnswerValue | null;
  appliedSuggestedValue?: boolean;
  hideSuggestedValue?: boolean;
}

const RADIO_TO_SELECT_CONVERSION_THRESHOLD = 10;

const RadioGroup = ({
  options,
  onChange,
  questionId,
  'data-testid': testId,
  controlledValue,
  onClear,
  suggestedValue,
  appliedSuggestedValue,
  hideSuggestedValue = false,
}: RadioProps) => {
  const value = useAppSelector((state) =>
    selectQuestionValue(state, questionId)
  );

  const valueToUse =
    controlledValue || value || (suggestedValue && appliedSuggestedValue);

  const dispatch = useAppDispatch();

  //@ts-ignore
  const onClickOptionHandler = (e) => {
    const val = e.target.value;
    if (val && val === valueToUse) {
      suggestedValue && dispatch(removeSuggestedQuestionValues([questionId]));
      onChange('');
    } else {
      val && onChange(val);
    }
  };

  const shouldRenderAiIndicator = (option: QuestionOptions) =>
    !hideSuggestedValue &&
    !appliedSuggestedValue &&
    (option.value === suggestedValue || option.label === suggestedValue);

  return (
    <RadioGroupWrapper>
      <MUIRadioGroup
        name='controlled-radio-buttons-group'
        data-testid={testId}
        value={valueToUse}
        row={false}
        // @ts-ignore
        onClear={onClear}
      >
        {options?.map((option) => (
          <FormControlLabelWrapper key={option.value}>
            <FormControlLabel
              value={option.value}
              control={<Radio data-testId={`question-option-${questionId}`} />}
              label={option.label}
              onClick={onClickOptionHandler}
            />
            {shouldRenderAiIndicator(option) && <AiIndicator>AI</AiIndicator>}
          </FormControlLabelWrapper>
        ))}
      </MUIRadioGroup>
    </RadioGroupWrapper>
  );
};

const Radios = (props: RadioProps) => {
  // A superficial change to the UI which converts lengthy options to a select.
  // In future this will hopefully be replaced with a more sophisticated explicit question type.
  if (props.options.length > RADIO_TO_SELECT_CONVERSION_THRESHOLD) {
    return <Select {...props} />;
  }

  return <RadioGroup {...props} />;
};

export default Radios;
