import { makeRequest } from '@/api/axios/client';
import { PaginationResponse } from '../types';
import { RawOrganisationsApiData } from './types';
import { getFormattedOrganisations } from '@/api/transforms/admin/getFormattedOrganisations';

const getAllOrganisations = async ({
  limit = 10,
  offset = 0,
  sortColumn = 'createdAt',
  sortDirection = 'desc',
  filters,
}: {
  limit?: number;
  offset?: number;
  sortColumn?: string;
  sortDirection?: string;
  filters?: { column: string; values: string[] }[];
}) => {
  const { data, error } = await makeRequest<{
    data: RawOrganisationsApiData[];
    pagination: PaginationResponse;
  }>({
    method: 'POST',
    url: `${
      process.env.NEXT_PUBLIC_REST_API_URL
    }/organisations/search?sortColumn=${sortColumn}&sortDirection=${
      sortDirection?.toUpperCase() || 'DESC'
    }`,
    data: {
      pagination: {
        limit,
        offset,
      },
      ...(filters && { filters }),
    },
  });

  return {
    data: error
      ? null
      : {
          data: getFormattedOrganisations(data!.data),
          pagination: data!.pagination,
        },
    error,
  };
};

export default getAllOrganisations;
