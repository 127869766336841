import { emptyPagination } from '@/api/admin/types';
import { QuestionnaireMinimal } from './questionnaire';
import { UserRoleNames } from './config';

export const defaultFiltersObject: Filters = {
  pagination: {
    ...emptyPagination,
    limit: 10,
  },
  filters: [],
  sort: {},
  searches: [
    {
      column: '',
      value: '',
    },
  ],
};

export interface SearchProps {
  column: string;
  value: string;
}
export type SortingDirection = 'ASC' | 'DESC' | {};
export interface Filters {
  searches: SearchProps[];
  filters: { column: string; values: string[] }[];
  sort: {
    column?: string;
    direction?: string;
  };
  pagination: {
    offset: number;
    limit: number;
    total: number;
    hasNext: boolean;
    hasPrev: boolean;
    page?: number;
    pages?: number;
  };
}

export enum ContributorStatus {
  Active = 'active',
  Invited = 'invited',
  Suspended = 'suspended',
  Pending = 'pending',
}

export enum ContributorActions {
  EDIT_CONTRIBUTOR = 'EDIT_CONTRIBUTOR',
  DELETE_CONTRIBUTOR = 'DELETE_CONTRIBUTOR',
}

export enum ContributorFunction {
  DFIR = 'DFIR',
  LEGAL = 'LEGAL',
  TA_ENGAGEMENT_SPECIALIST = 'TA_ENGAGEMENT_SPECIALIST',
  CLAIMS_TEAM = 'CLAIMS_TEAM',
}

export enum UserStatus {
  Active = 'active',
  Invited = 'invited',
  Suspended = 'suspended',
  Pending = 'pending',
}

export enum OrganisationRole {
  IncidentManager = 'INCIDENT-MANAGER',
  Insurer = 'INSURER',
}

export enum OrganisationStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
}

export const UserRoleNamesLabelMap: Record<UserRoleNames, string> = {
  [UserRoleNames.INSURER]: 'Insurer',
  [UserRoleNames['POLUS-RESPONSE:TEMP-EXTRA']]: 'Temp Extra',
  [UserRoleNames['INCIDENT-MANAGER']]: 'Incident Manager',
  [UserRoleNames['POLUS-RESPONSE:ADMIN']]: 'Admin',
  [UserRoleNames['POLUS-RESPONSE:USER']]: 'User',
  [UserRoleNames['INCIDENT-MANAGER-PRIMARY-CONTACT']]:
    'Incident Manager (Primary Contact)',
  [UserRoleNames['INSURER-PRIMARY-CONTACT']]: 'Insurer (Primary Contact)',
  [UserRoleNames.CONTRIBUTOR]: 'Contributor',
};

export enum IncidentRole {
  Contributor = 'CONTRIBUTOR',
  IncidentManagerPrimaryContact = 'INCIDENT-MANAGER-PRIMARY-CONTACT',
  InsurerPrimaryContact = 'INSURER-PRIMARY-CONTACT',
  DFIR = 'DFIR',
  Legal = 'LEGAL',
  TAEngagementSpecialist = 'TA_ENGAGEMENT_SPECIALIST',
  CLAIMS_TEAM = 'CLAIMS_TEAM',
}

export const IncidentRoleLabelMap: Record<IncidentRole, string> = {
  [IncidentRole.Contributor]: 'Contributor',
  [IncidentRole.IncidentManagerPrimaryContact]: 'Primary Contact',
  [IncidentRole.InsurerPrimaryContact]: 'Primary Contact',
  [IncidentRole.DFIR]: 'DFIR',
  [IncidentRole.Legal]: 'Legal',
  [IncidentRole.TAEngagementSpecialist]: 'TA Engagement Specialist',
  [IncidentRole.CLAIMS_TEAM]: 'Claims Team',
};

export enum IncidentStatus {
  NotStarted = 'NOT_STARTED',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
}

export interface User {
  id: string;
  name: string;
  email: string;
  status: UserStatus;
  roles: UserRoleNames[];
  userRole?: string[];
  organisation?: {
    id: string;
    name: string;
    status?: string;
  };
  createdAt?: string;
  updatedAt?: string;
}

export interface NewUser {
  organisation: string;
  email: string;
  name: string;
  role?: string[];
}

export interface MandatoryQuestionCompletionStatus {
  visible: number;
  completedVisible: number;
}

export interface Pagination {
  offset: number;
  limit: number;
  total: number;
  next: boolean;
  previous: boolean;
}

export interface Incident {
  id: string;
  title: string;
  status: IncidentStatus;
  createdAt: string;
  closedAt: string;
  incidentManagerOrgName: string;
  incidentManagerOrgPrimaryContactFullName: string;
  insurerName: string;
  insurerPrimaryContactFullName: string;
  insurerId?: string;
  insurerPrimaryContactId?: string;
  managerOrgId?: string;
  managerOrgPrimaryContactId?: string;
  caseTypes?: string[];
  roles?: IncidentRole[];
  closureCompletionRate?: number;
  // GET incident/{id} view only
  description?: string;
  victimOrg?: string;
  intake?: {
    id: string;
    startDate: string;
    endDate: string;
    caseType: string[];
    status: boolean;
  };
  closure?: {
    id: string;
    startDate: string;
    endDate: string;
    status: boolean;
    completionRate: number;
  };
}

export interface Organisation {
  id: string;
  name: string;
  roles: OrganisationRole[];
  externalId?: string;
  shortName?: string;
  sector?: string;
  domainList?: string;
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
}

export interface Contributor {
  id: string;
  email: string;
  name: string;
  organization: string;
  status: ContributorStatus;
  roles?: ContributorFunction[];
  completionRate?: number | null;
}

export interface AdminSlice {
  questionnaires: {
    ids: string[];
    entities: {
      [key: string]: QuestionnaireMinimal;
    };
  };
  incidents: {
    ids: string[];
    entities: {
      [key: string]: Incident;
    };
    filters: Filters;
    isLoading: boolean;
  };
  contributors: {
    ids: string[];
    entities: {
      [key: string]: Contributor;
    };
    filters: Filters;
    isLoading: boolean;
  };
  organisations: {
    ids: string[];
    entities: {
      [key: string]: Organisation;
    };
    filters: Filters;
    isLoading: boolean;
    isUpdating: boolean;
    recentlyCreatedOrgFields: Record<
      string,
      {
        organisationId: string;
        organisationName: string;
      }
    >;
    orgCreationDestination?: string;
  };
  users: {
    ids: string[];
    entities: {
      [key: string]: User;
    };
    filters: Filters;
    isLoading: boolean;
  };
}
