import { useAppDispatch, useAppSelector } from '@/hooks';
import { ListActions } from '../styled';
import {
  Badge,
  Box,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  selectEntityModificationsCount,
  selectOutdatedEntities,
  setActiveEntitySearchList,
  setCaseTypeInEdit,
} from '@/store/slices/editor';
import { removeAllTags } from '@/utils/helpers';
import { CaseTypeId } from '@/store/types/basicTypes';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Button } from '@s-rm/react-ui-lib';
import { selectCaseType } from '@/store/slices/caseTypes';
import { SecondaryBadge } from '../../styled';

const CaseTypeListItem = ({
  id,
  onDelete,
}: {
  id: CaseTypeId;
  onDelete: () => void;
}) => {
  const dispatch = useAppDispatch();
  const { label } = useAppSelector((state) => selectCaseType(state, id)) || {
    label: '',
  };
  const entityModifications = useAppSelector((state) =>
    selectEntityModificationsCount(state, 'caseTypes', id)
  );
  const isOutdated = !!useAppSelector(selectOutdatedEntities).caseTypes[id];

  const handleClickModule = () => {
    dispatch(setActiveEntitySearchList(null));
    dispatch(setCaseTypeInEdit({ id }));
  };

  return (
    <ListItem
      sx={{ background: 'white' }}
      secondaryAction={
        <ListActions>
          <Button square>
            <DeleteOutlineOutlinedIcon
              onClick={onDelete}
              data-testid='btn-delete-ctype-item'
            />
          </Button>
        </ListActions>
      }
      disablePadding
    >
      <ListItemButton
        style={{ paddingRight: '100px' }}
        data-testid={id}
        onClick={handleClickModule}
      >
        <Box position='absolute' top='-10px'>
          <Tooltip title='Current draft contains changes to this case type'>
            <Badge color='secondary' badgeContent={entityModifications}></Badge>
          </Tooltip>
          <Tooltip title='This case type is out of date with the latest published draft'>
            <SecondaryBadge badgeContent={isOutdated ? 'outdated' : null} />
          </Tooltip>
        </Box>
        <ListItemText>
          <Typography noWrap>{removeAllTags(label)}</Typography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
};

export default CaseTypeListItem;
