import {
  ContributorStatus,
  ContributorFunction,
  Contributor,
} from '@/store/types/admin';

export interface RawContributorApiData {
  ID: string;
  FullName: string;
  Email: string;
  Organisation: {
    Id: string;
    Name: string;
    ShortName: string;
    Sector: string;
    DomainList: string;
    Status: string;
    Roles: null;
    CreatedAt: string | null;
    CreatedBy: string | null;
    UpdatedAt: string | null;
    DeletedAt: string | null;
  };
  Status: ContributorStatus;
}

const getFormattedContributors = (data: any[]): Contributor[] => {
  return data.map((contributor) => {
    return {
      id: contributor.ID,
      name: contributor.FullName,
      email: contributor.Email,
      organization: contributor?.Organisation?.Name ?? '',
      status: contributor.Status,
      roles: contributor.Roles as ContributorFunction[],
      completionRate: null,
    };
  });
};

export default getFormattedContributors;
