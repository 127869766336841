export const configMock = {
  hasInitialised: true,
  isCaseValid: true,
  errors: [],
  featureFlags: {},
  user: {
    fullName: 'Person one',
    firstName: 'Person',
    lastName: 'One',
    emailAddress: 'person@person.com',
    telephone: '',
  },
  accessPermissions: ['VIEW_USERS', 'VIEW_ORGANISATIONS', 'VIEW_INCIDENTS'],
};
