import updateQuestionnaire from '@/api/mutations/updateQuestionnaire';
import {
  setQuestionnaireStartedAt,
  setIsQuestionnaireActive,
  setQuestionnaireCompletedAt,
} from '../slices/questionnaire';
import { AppThunk } from '../store';

const submitQuestionnaire =
  (id?: string): AppThunk =>
  async (dispatch) => {
    dispatch(setIsQuestionnaireActive(false));
    dispatch(setQuestionnaireCompletedAt(new Date().toISOString()));
    (await updateQuestionnaire()) as { data: any };
  };

export default submitQuestionnaire;
