import client from '@/api/client';
//@ts-ignore
import { UpdateQuestionnaire } from '@/api/gql/mutations.gql';
import { ErrorCodes } from '@/constants/errors';
import { store } from '@/store';
import { createError } from '@/utils/errorHandler';
import { getSelectedCaseTypesToSave } from '../utils/getSelectedCaseTypesToSave';

export interface UpdateCaseSaveData {
  selectedIndicatorIds?: string[];
  dismissedIndicatorIds?: string[];
  selectedCaseTypes?: string[];
}

export const updateQuestionnaire = async () => {
  const caseTypes = store.getState().caseTypes.entities;

  const variables = {
    id: store.getState().questionnaire.questionnaireId,
    startedAt: store.getState().questionnaire.startedAt,
    completedAt: store.getState().questionnaire.completedAt,
    isActive: store.getState().questionnaire.isActive,
    projectId: store.getState().questionnaire.projectId,
    notes: store.getState().questionnaire.description,
    triggeredIntegrations: store.getState().questionnaire.triggeredIntegrations,
    config: {
      label: store.getState().questionnaire.label,
      staticModuleIds: store.getState().modules.staticModuleIds,
      features: {
        diagnosis: {
          toggled: store.getState().questionnaire.isDiagnosisEnabled,
          selectedIndicatorIds: store.getState().indicators.selected,
          dismissedIndicatorIds: store.getState().indicators.dismissed,
          selectedCaseTypeIds: getSelectedCaseTypesToSave(caseTypes, store),
        },
        addModules: {
          toggled: store.getState().questionnaire.isAddModulesEnabled,
          manuallySelectedModules: store.getState().modules.manuallySelected,
        },
        addNotes: {
          toggled: store.getState().questionnaire.isNotesEnabled,
        },
      },
    },
  };

  try {
    const { data, errors } = await client.mutate({
      mutation: UpdateQuestionnaire,
      variables,
    });

    if (errors) {
      createError({
        code: ErrorCodes.UpdateCase,
        error: new Error(JSON.stringify(errors)),
        displaySnackbar: true,
      });
    }

    return { data, errors };
  } catch (error) {
    createError({
      code: ErrorCodes.UpdateCase,
      displaySnackbar: true,
    });
  }
};

export default updateQuestionnaire;
