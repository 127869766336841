import { useAppSelector } from '@/hooks';
import { selectManuallySelectedModules } from '@/store/slices/modules';
import getModuleIdsFromDisplayStructure from '../components/utils/getModuleIdsFromDisplayStructure';
import useModuleDisplayStructure from './useModuleDisplayStructure';
import { selectIsDiagnosisEnabled } from '@/store/slices/questionnaire';
import { ModuleId } from '@/store/types/basicTypes';

const useOrderedModules = () => {
  const manuallySelectedModules = useAppSelector(selectManuallySelectedModules);
  const isUsingDiagnosis = useAppSelector(selectIsDiagnosisEnabled);
  const moduleDisplayStructure = useModuleDisplayStructure();

  if (isUsingDiagnosis) {
    return [
      ...getModuleIdsFromDisplayStructure(moduleDisplayStructure),
      ...manuallySelectedModules,
    ];
  }

  return moduleDisplayStructure.reduce(
    (acc, group) => [...acc, ...group.relatedModules!],
    [] as ModuleId[]
  );
};

export default useOrderedModules;
