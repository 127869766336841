import { AccessPermissions, UserRoleNames } from '@/store/types/config';
import getPermissionsFromRoles from './getPermissionsFromRoles';
import { User } from '@/store/types/admin';

export interface UserWithPermissions {
  user: User;
  permissions: AccessPermissions[];
}

const getAuthorizedUserTransform = (data: any): UserWithPermissions => {
  const user = {
    name: data.name,
    email: data.email,
    id: data.id,
    roles: Object.keys(UserRoleNames)
      .filter((role) =>
        data.role_names.includes(
          UserRoleNames[role as keyof typeof UserRoleNames]
        )
      )
      .map((role) => UserRoleNames[role as keyof typeof UserRoleNames]),
    status: data.status,
  };
  return {
    user,
    permissions: getPermissionsFromRoles(user.roles),
  };
};

export default getAuthorizedUserTransform;
