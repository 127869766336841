import axios, { AxiosError, AxiosInstance, Method } from 'axios';
import { HttpStatus } from './types';

export const getClient = ({
  requiresBearer,
  disableAuthInterceptor,
}: { requiresBearer?: boolean; disableAuthInterceptor?: boolean } = {}) => {
  const client = axios.create({
    withCredentials: true,
    headers: {
      ...(process.env.NEXT_PUBLIC_CUSTOM_AUTH_TOKEN
        ? {
            'X-Custom-Authorization': `${requiresBearer ? 'Bearer ' : ''}${
              process.env.NEXT_PUBLIC_CUSTOM_AUTH_TOKEN
            }`,
          }
        : {}),
    },
  });

  if (disableAuthInterceptor) {
    return client;
  }

  client.interceptors.response.use(
    (response) => response,
    async (error) => {
      switch (error.response?.status) {
        case HttpStatus.Unauthorized:
          window.location.href = `/auth/login?returnUrl=${window.location.href}`;
          break;
        case HttpStatus.Forbidden:
          window.location.href = '/unauthorized';
          break;
        default:
          break;
      }
      return Promise.reject(error);
    }
  );

  return client;
};

interface RequestParams<T = any> {
  method?: Method;
  url: string;
  params?: Record<string, any>;
  data?: T;
}

export interface RequestResponse<T = any> {
  data: T | null;
  error: AxiosError | null;
}

export const makeRequest = async <T = any>({
  method = 'GET',
  url,
  params = {},
  data,
}: RequestParams): Promise<RequestResponse<T>> => {
  const client: AxiosInstance = getClient();

  try {
    const response = await client.request<T>({
      method,
      url,
      params,
      data,
    });

    return {
      data: response.data,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {
      data: null,
      error: error as AxiosError,
    };
  }
};
