import { Box, styled } from '@mui/material';

export const LoadingBox = styled(Box)`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: white;
`;
