import { Box, CircularProgress } from '@mui/material';
import NoDataText from './NoDataText';

const PageLoader = ({ text }: { text?: string }) => (
  <Box
    data-testid='page-loader'
    display='flex'
    justifyContent='center'
    alignItems='center'
    height='100%'
  >
    <Box textAlign='center'>
      <CircularProgress />
      <NoDataText fontSize='1.5rem' mt={5}>
        {text ?? 'Loading...'}
      </NoDataText>
    </Box>
  </Box>
);

export default PageLoader;
