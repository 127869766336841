import React, { ReactNode } from 'react';
import { useAppSelector } from '@/hooks';
import { selectHasInitialised } from '@/store/slices/config';
import { getIsAdminRoute, getIsFeatureFlagRoute } from '@/utils/router';
import { useRouter } from 'next/router';
import { useFeatureFlags } from '../FeatureFlags/Provider';
import useAnalytics from '@/hooks/useAnalytics';
import AdminLayout from './AdminLayout/AdminLayout';
import QuestionnaireLayout from './QuestionnaireLayout/QuestionnaireLayout';
import { isOnLoginRoute } from '@/utils/helpers';
import { Box } from '@mui/material';
import PageLoader from '../Placeholder/PageLoader';

type Props = {
  children?: ReactNode;
  title?: string;
};

const Layout = ({ children, title = 'IR Intake' }: Props) => {
  const hasInitialised = useAppSelector(selectHasInitialised);
  const router = useRouter();
  const isFeatureFlagRoute = getIsFeatureFlagRoute(router);
  const { hasFetchedFlags } = useFeatureFlags();
  const isFetchingFlags =
    !hasFetchedFlags || (!isFeatureFlagRoute && !hasInitialised);
  useAnalytics();

  const isAdminRoute = getIsAdminRoute(router);
  const isLoginRoute = isOnLoginRoute();

  if (isFetchingFlags && !isOnLoginRoute()) {
    return (
      <Box data-testid='layout-page' height='100vh'>
        <PageLoader text='Loading...' />
      </Box>
    );
  }

  if (isLoginRoute) {
    return <>{children}</>;
  }

  let LayoutComponent = null;

  if (isAdminRoute) {
    LayoutComponent = AdminLayout;
  } else {
    LayoutComponent = QuestionnaireLayout;
  }
  return <LayoutComponent title={title}>{children}</LayoutComponent>;
};

export default Layout;
