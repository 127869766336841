import { makeRequest } from '@/api/axios/client';
import { Filters } from '@/store/types/admin';

import getFormattedContributors, {
  RawContributorApiData,
} from '@/api/transforms/admin/getFormattedContributors';

const fetchContributors = async ({
  filters,
  incidentId,
  searchTerm: queryString,
}: {
  filters: Filters;
  incidentId: string;
  searchTerm?: string;
}) => {
  const { data, error } = await makeRequest<{
    data: RawContributorApiData[];
    pagination: {
      offset: number;
      limit: number;
      total: number;
    };
  }>({
    method: 'POST',
    url: `${process.env.NEXT_PUBLIC_REST_API_URL}/users/search/preset/incident-contributors`,
    data: {
      pagination: {
        offset: filters.pagination?.offset || 0,
        limit: filters.pagination?.limit || 10,
      },
      incidentId,
      ...(queryString ? { queryString } : {}),
    },
  });
  return {
    data: error
      ? null
      : {
          data: getFormattedContributors(data!.data),
          pagination: data!.pagination,
        },
    error,
  };
};

export default fetchContributors;
