import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialState } from '@/store/initialState';
import { RootState } from '../store';
import { Client, Project, Questionnaire } from '../types/questionnaire';

export const questionnaireSlice = createSlice({
  name: 'questionnaire',
  initialState: initialState.questionnaire,
  reducers: {
    setDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
    setDescriptionAtTimeOfSuggestIndicators: (
      state,
      action: PayloadAction<string>
    ) => {
      state.descriptionPreSuggestedIndicators = action.payload;
    },
    setCaseId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    setProject: (state, action: PayloadAction<Project>) => {
      state.project = action.payload;
    },
    setClient: (state, action: PayloadAction<Client>) => {
      state.client = action.payload;
    },
    setIsQuestionnaireActive: (state, action: PayloadAction<boolean>) => {
      state.isActive = action.payload;
    },
    setQuestionnaireStartedAt: (
      state,
      action: PayloadAction<null | string>
    ) => {
      state.startedAt = action.payload;
    },
    setQuestionnaireCompletedAt: (
      state,
      action: PayloadAction<null | string>
    ) => {
      state.completedAt = action.payload;
    },
    setTriggeredIntegrations: (state, action: PayloadAction<string[]>) => {
      state.triggeredIntegrations = action.payload;
    },
    setIsDiagnosisEnabled: (state, action: PayloadAction<boolean>) => {
      state.isDiagnosisEnabled = action.payload;
    },
    setIsNotesEnabled: (state, action: PayloadAction<boolean>) => {
      state.isNotesEnabled = action.payload;
    },
    setIsAddModulesEnabled: (state, action: PayloadAction<boolean>) => {
      state.isAddModulesEnabled = action.payload;
    },
    setLabel: (state, action: PayloadAction<string>) => {
      state.label = action.payload;
    },
    setProjectId: (state, action: PayloadAction<string>) => {
      state.projectId = action.payload;
    },
    setQuestionnaireId: (state, action: PayloadAction<string>) => {
      state.questionnaireId = action.payload;
    },
  },
});

export const {
  setDescription,
  setCaseId,
  setProject,
  setClient,
  setDescriptionAtTimeOfSuggestIndicators,
  setQuestionnaireStartedAt,
  setQuestionnaireCompletedAt,
  setTriggeredIntegrations,
  setIsQuestionnaireActive,
  setIsDiagnosisEnabled,
  setIsNotesEnabled,
  setIsAddModulesEnabled,
  setLabel,
  setProjectId,
  setQuestionnaireId,
} = questionnaireSlice.actions;

export const selectCurrentQuestionnaire = (state: RootState): Questionnaire =>
  state.questionnaire;
export const selectQuestionnaireDesription = (state: RootState) =>
  state.questionnaire.description;
export const selectDesriptionPreSuggestedIndicators = (state: RootState) =>
  state.questionnaire.descriptionPreSuggestedIndicators;
export const selectCaseId = (state: RootState) => state.questionnaire.id;
export const selectProjectId = (state: RootState) =>
  state.questionnaire.projectId;
export const selectQuestionnaireLabel = (state: RootState) =>
  state.questionnaire.label;
export const selectQuestionnaireId = (state: RootState) =>
  state.questionnaire.questionnaireId;
export const selectProject = (state: RootState) => state.questionnaire.project;
export const selectClient = (state: RootState) => state.questionnaire.client;
export const selectIsQuestionnaireActive = (state: RootState) =>
  state.questionnaire.isActive;
export const selectQuestionnaireStartedAt = (state: RootState) =>
  state.questionnaire.startedAt;
export const selectQuestionnaireCompletedAt = (state: RootState) =>
  state.questionnaire.completedAt;
export const selectTriggeredIntegrations = (state: RootState) =>
  state.questionnaire.triggeredIntegrations;
export const selectIsDiagnosisEnabled = (state: RootState) =>
  state.questionnaire.isDiagnosisEnabled;
export const selectIsNotesEnabled = (state: RootState) =>
  state.questionnaire.isNotesEnabled;
export const selectIsAddModulesEnabled = (state: RootState) =>
  state.questionnaire.isAddModulesEnabled;

export default questionnaireSlice.reducer;
