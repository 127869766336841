import React, { useEffect, useState } from 'react';
import { Button, LoadingButton, SnackbarTypes } from '@s-rm/react-ui-lib';
import { useAppDispatch, useAppSelector } from '@/hooks';

import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { InputLabelRequired } from '../styled';
import { contributorRolesData } from '@/data/admin';
import { selectContributor } from '@/store/slices/admin/admin';
import { ContributorFunction } from '@/store/types/admin';
import { useRouter } from 'next/router';
import assignContributorToIncident from '@/api/admin/contributors/assignContributorToIncident';
import updateSnackbarNotification from '@/store/thunks/updateSnackbar';

const EditContributorDialog = ({
  onCancel,
  contributorId,
  onSave,
}: {
  onCancel: () => void;
  contributorId: string;
  onSave: () => void;
}) => {
  const theme = useTheme();
  const isOpen = !!contributorId;
  const router = useRouter();
  const incidentId = router.query.id as string;
  const contributor = useAppSelector((state) =>
    selectContributor(state, contributorId)
  );
  const [selectedRoles, setSelectedRoles] = useState<ContributorFunction[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useAppDispatch();

  const getDisplayLabel = (selected: any, placeholderValue: string = '') => {
    if (Array.isArray(selected)) {
      return selected.length > 0 ? selected.join(', ') : placeholderValue;
    }
    return selected?.label ? selected.label : placeholderValue;
  };

  const roleOptions = Object.keys(contributorRolesData).map((role) => ({
    value: role,
    label: contributorRolesData[role as ContributorFunction].label,
  }));

  const handleSave = async () => {
    setIsSaving(true);
    const { error } = await assignContributorToIncident({
      incidentId,
      userId: contributorId,
      roles: selectedRoles,
    });
    if (error) {
      return dispatch(
        updateSnackbarNotification({
          message: 'An error occurred while assigning the user to the incident',
          type: SnackbarTypes.Error,
        })
      );
    }
    setIsSaving(false);
    onSave();
  };

  useEffect(() => {
    setSelectedRoles(contributor?.roles ?? []);
  }, [contributor]);

  if (!contributor) {
    return null;
  }

  return (
    <>
      <Dialog open={isOpen} onClose={onCancel} maxWidth='sm' fullWidth>
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            variant='h3'
            sx={{ fontWeight: 700 }}
            data-testid='assign-contributor-dialog-title'
          >
            Edit contributor: {contributor.name}
          </Typography>
          <IconButton aria-label='close' onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <InputLabelRequired
            shrink
            htmlFor='contributor-role'
            required
            sx={{ mt: 2 }}
          >
            Role in current project
          </InputLabelRequired>
          <FormControl fullWidth>
            <Select
              data-testid={'select-contrib-role'}
              fullWidth
              multiple
              size='small'
              name='contributor-role'
              value={selectedRoles}
              onChange={(e) =>
                setSelectedRoles(e.target.value as ContributorFunction[])
              }
              displayEmpty
              renderValue={(selected) => {
                if (!selectedRoles.length) {
                  return (
                    <Typography variant='body1'>
                      {getDisplayLabel(selected, 'Select a contributor role')}
                    </Typography>
                  );
                }
                return selected
                  .map(
                    (role) =>
                      contributorRolesData[role as ContributorFunction]?.label
                  )
                  .join(', ');
              }}
            >
              {roleOptions.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  <Checkbox
                    checked={selectedRoles.includes(
                      value as ContributorFunction
                    )}
                  />
                  <ListItemText primary={label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions
          sx={{
            padding: 2,
            borderTop: `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <Button onClick={onCancel} variant='outlined'>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleSave}
            variant='contained'
            loading={isSaving}
            disabled={!selectedRoles.length}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditContributorDialog;
