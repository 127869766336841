import { ErrorCodes } from '@/constants/errors';

export const convertExpirySecondsToDateObject = (seconds: number) => {
  const currentDate = new Date();
  return new Date(currentDate.getTime() + seconds * 1000);
};

export const validateInitialData = (initialData: any) => {
  if (!initialData) {
    return ErrorCodes.InitialDataFetch;
  }

  // If the GQL response contains an error object.
  if (initialData.error) {
    return ErrorCodes.InitialDataGQL;
  }
};

export const isUnauthorizedOrForbidden = (code: number) =>
  [401, 403].includes(code);
