export enum ErrorCodes {
  InitialDataFetch,
  InitialDataGQL,
  NoCaseId,
  Unknown,
  NoTokenReturned,
  NoTokenReturnedFromRefresh,
  NoLoginUrlReturned,
  NetworkError,
  InvalidApiToken,
  GraphQLError,
  CreateSession,
  DeleteSession,
  MergeSession,
  ArchiveQuestion,
  ArchiveModule,
  ArchiveModuleGroup,
  ArchiveCaseType,
  AddCaseTypeVersion,
  MakeTestCase,
  ArchiveTip,
  ArchiveAdvice,
  UpdateCase,
  ClearCase,
  SaveAnswer,
  AddDescription,
  CloseSession,
  CreateAdvice,
  CreateCaseType,
  CreateModule,
  CreateModuleGroup,
  CreateQuestion,
  CreateTip,
  EditAdvice,
  EditCaseType,
  EditModule,
  EditModuleGroup,
  EditQuestion,
  EditTip,
  PublishSession,
  TriggerIntegration,
  S1SiteExists,
  ResetEditorSessionChange,
  CreateIncident,
  GetAllIncidents,
  GetIncidentDetail,
  CreateUser,
  GetAllOrganisations,
  CreateOrganisation,
  GetIncidentDetails,
  UpdateIncidentDetails,
  GetUserDetails,
  GetAllUsers,
  UpdateOrganisation,
  GetCaseTypes,
  Authentication,
  DeleteIncident,
  UnexpectedAuthError,
  InvalidUserSession,
  LoginURL,
  UpdateQuestionnaireCompletion,
  Logout,
}

export const errors = {
  [ErrorCodes.Logout]: {
    message: 'There was a problem logging you out, please try again',
  },
  [ErrorCodes.Authentication]: {
    message: 'Authentication failed. Please try again.',
  },
  [ErrorCodes.UnexpectedAuthError]: {
    message:
      'An unexpected authentication error occured. Please try again later.',
  },
  [ErrorCodes.InvalidUserSession]: {
    message: 'User session invalid. Please try again.',
  },
  [ErrorCodes.InitialDataFetch]: {
    message: 'API error fetching initial data',
  },
  [ErrorCodes.LoginURL]: {
    message: 'An error occured while fetching the login URL. Please try again.',
  },
  [ErrorCodes.NoCaseId]: {
    message: 'No case found, please check the ID and try again.',
  },
  [ErrorCodes.Unknown]: {
    message: 'An error has been encountered.',
  },
  [ErrorCodes.NoTokenReturned]: {
    message: 'No token returned from server.',
  },
  [ErrorCodes.NoTokenReturnedFromRefresh]: {
    message: 'No token returned from server when refreshing.',
  },
  [ErrorCodes.NetworkError]: {
    message: 'Network error.',
  },
  [ErrorCodes.NoLoginUrlReturned]: {
    message: 'No login url returned from server.',
  },
  [ErrorCodes.InvalidApiToken]: {
    message: 'Invalid API token.',
  },
  [ErrorCodes.GraphQLError]: {
    message: 'GraphQL error occured while executing the operation.',
  },
  [ErrorCodes.CreateSession]: {
    message: 'We encountered a problem creating the draft, please retry.',
  },
  [ErrorCodes.DeleteSession]: {
    message: 'We encountered a problem deleting the draft, please retry.',
  },
  [ErrorCodes.MergeSession]: {
    message: 'We encountered a problem publishing the draft, please retry.',
  },
  [ErrorCodes.ArchiveQuestion]: {
    message: 'We encountered a problem deleting this question, please retry.',
  },
  [ErrorCodes.ArchiveModule]: {
    message: 'We encountered a problem deleting this module, please retry.',
  },
  [ErrorCodes.ArchiveModuleGroup]: {
    message:
      'We encountered a problem deleting this module group, please retry.',
  },
  [ErrorCodes.AddCaseTypeVersion]: {
    message: 'We encountered a problem updating this case type, please retry.',
  },
  [ErrorCodes.MakeTestCase]: {
    message: 'We encountered a problem creating a new case type, please retry.',
  },
  [ErrorCodes.ArchiveTip]: {
    message: 'We encountered a problem deleting this tip, please retry.',
  },
  [ErrorCodes.ArchiveAdvice]: {
    message: 'We encountered a problem deleting this advice, please retry.',
  },
  [ErrorCodes.UpdateCase]: {
    message: 'We encountered a problem updating the case, please retry.',
  },
  [ErrorCodes.ClearCase]: {
    message: 'We encountered a problem clearing the case, please retry.',
  },
  [ErrorCodes.SaveAnswer]: {
    message: 'We encountered a problem saving the answer, please retry.',
  },
  [ErrorCodes.ArchiveCaseType]: {
    message: 'We encountered a problem deleting the case type, please retry.',
  },
  [ErrorCodes.CloseSession]: {
    message: 'We encountered a problem closing the session, please retry.',
  },
  [ErrorCodes.CreateAdvice]: {
    message: 'We encountered a problem creating the advice, please retry.',
  },
  [ErrorCodes.CreateCaseType]: {
    message: 'We encountered a problem creating the case type, please retry.',
  },
  [ErrorCodes.CreateModule]: {
    message: 'We encountered a problem creating the module, please retry.',
  },
  [ErrorCodes.CreateModuleGroup]: {
    message:
      'We encountered a problem creating the module group, please retry.',
  },
  [ErrorCodes.CreateQuestion]: {
    message: 'We encountered a problem creating the question, please retry.',
  },
  [ErrorCodes.CreateTip]: {
    message: 'We encountered a problem creating the tip, please retry.',
  },
  [ErrorCodes.EditAdvice]: {
    message: 'We encountered a problem updating the advice, please retry.',
  },
  [ErrorCodes.EditCaseType]: {
    message: 'We encountered a problem updating the case type, please retry.',
  },
  [ErrorCodes.EditModule]: {
    message: 'We encountered a problem updating the module, please retry.',
  },
  [ErrorCodes.EditModuleGroup]: {
    message:
      'We encountered a problem updating the module group, please retry.',
  },
  [ErrorCodes.EditQuestion]: {
    message: 'We encountered a problem updating the question, please retry.',
  },
  [ErrorCodes.EditTip]: {
    message: 'We encountered a problem updating the tip, please retry.',
  },
  [ErrorCodes.PublishSession]: {
    message: 'We encountered a problem publishing the draft, please retry.',
  },
  [ErrorCodes.TriggerIntegration]: {
    message:
      'We encountered a problem triggering the integration, please check if a case type exists and retry.',
  },
  [ErrorCodes.S1SiteExists]: {
    message:
      'SentinelOne integration failed. There is likely a site already created for this case, please contact support.',
  },
  [ErrorCodes.ResetEditorSessionChange]: {
    message: 'We encountered a problem resetting the entity.',
  },
  [ErrorCodes.CreateIncident]: {
    message: 'We encountered a problem creating a new incident, please retry.',
  },
  [ErrorCodes.GetAllIncidents]: {
    message: 'We encountered a problem retrieving all incidents, please retry.',
  },
  [ErrorCodes.GetIncidentDetail]: {
    message:
      'We encountered a problem retrieving the incident details, please retry.',
  },
  [ErrorCodes.GetAllOrganisations]: {
    message:
      'We encountered a problem retrieving the organisations list, please retry.',
  },
  [ErrorCodes.CreateOrganisation]: {
    message:
      'We encountered a problem creating a new organisation, please retry.',
  },
  [ErrorCodes.CreateUser]: {
    message: 'We encountered a problem creating the user, please retry.',
  },
  [ErrorCodes.GetIncidentDetails]: {
    message:
      'We encountered a problem fetching the incident details, please retry.',
  },
  [ErrorCodes.UpdateIncidentDetails]: {
    message: 'We encountered a problem updating the incident, please retry.',
  },
  [ErrorCodes.GetUserDetails]: {
    message:
      'We encountered a problem fetching the user details, please retry.',
  },
  [ErrorCodes.GetAllUsers]: {
    message: 'We encountered a problem fetching the users, please retry.',
  },
  [ErrorCodes.UpdateOrganisation]: {
    message:
      'We encountered a problem updating the organisation, please retry.',
  },
  [ErrorCodes.GetCaseTypes]: {
    message: 'We encountered a problem fetching the case types, please retry.',
  },
  [ErrorCodes.AddDescription]: {
    message: 'We encountered a problem updating the description, please retry.',
  },
  [ErrorCodes.DeleteIncident]: {
    message: 'We encountered a problem deleting the incident, please retry.',
  },
  [ErrorCodes.UpdateQuestionnaireCompletion]: {
    message:
      'We encountered a problem saving the questionnaire completion rate, please retry.',
  },
};
