import getAllUsers from '@/api/admin/user/getAllUsers';

export const getContributorsListItems = async ({
  limit,
  offset,
  isNewSearch = false,
  searchTerm = '',
}: {
  limit: number;
  offset: number;
  isNewSearch?: boolean;
  searchTerm?: string;
}) => {
  const { data } = await getAllUsers({
    limit,
    offset: isNewSearch ? 0 : offset,
    filters: searchTerm ? [{ column: 'name', values: [searchTerm] }] : [],
  });

  if (!data) {
    return { newItems: [], total: 0 };
  }

  const newItems = data.data.map((item) => ({
    id: item.id,
    name: item.name,
    email: item.email,
  }));

  return { newItems, total: data.pagination.total };
};

export const contributorsOptionsFormatter = (
  items: { id: string; name: string; email: string }[]
) => {
  return items.map((item) => ({
    value: item.id,
    label: item.name,
    secondaryLabel: item.email,
  }));
};
