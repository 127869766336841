import {
  selectModuleGroupsAsArray,
  selectSelectedModules,
  selectTriggeredModuleIds,
} from '@/store/slices/modules';
import { useAppSelector } from './storeHooks';

const useGetModulesWithGroups = () => {
  const selectedModuleIds = useAppSelector(selectSelectedModules);
  const triggeredModuleIds = useAppSelector(selectTriggeredModuleIds)?.map(
    (m) => m.moduleId
  );
  const moduleGroups = useAppSelector(selectModuleGroupsAsArray);

  const modulesToEvaluate = selectedModuleIds.filter(
    (id) => ![...(triggeredModuleIds ?? [])].includes(id)
  );

  const modulesWithGroups = modulesToEvaluate.filter((id) =>
    moduleGroups.some((group) => group.moduleIds.includes(id))
  );

  return modulesWithGroups;
};

export default useGetModulesWithGroups;
