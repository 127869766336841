import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialState } from '@/store/initialState';
import { RootState } from '../store';
import { PendingInterceptAction, SnackbarTypes } from '../types/ui';

export const uiSlice = createSlice({
  name: 'ui',
  initialState: initialState.ui,
  reducers: {
    setRefreshDialogStatus: (
      state,
      action: PayloadAction<{ isOpen: boolean }>
    ) => {
      state.refreshDialogStatus = {
        ...action.payload,
      };
    },
    setPrintableSummaryStatus: (
      state,
      action: PayloadAction<{ isOpen: boolean; isAutoCopy: boolean }>
    ) => {
      state.printableSummaryStatus = {
        ...state.printableSummaryStatus,
        ...action.payload,
      };
    },
    setSnackbarNotification: (
      state,
      action: PayloadAction<{
        message: React.ReactNode;
        action?: React.ReactNode;
        type?: SnackbarTypes;
        disableAutoHide?: boolean;
      }>
    ) => {
      state.snackbarNotification = {
        ...action.payload,
        action: action.payload.action ?? null,
        type: action.payload.type ?? SnackbarTypes.Success,
        disableAutoHide: action.payload.disableAutoHide ?? false,
      };
    },
    removeSnackbarNotification: (state) => {
      state.snackbarNotification = {
        message: null,
        action: null,
        type: state.snackbarNotification.type || SnackbarTypes.Success,
        disableAutoHide: false,
      };
    },
    setDescriptionPopoutWindowStatus: (
      state,
      action: PayloadAction<{ isOpen: boolean }>
    ) => {
      state.descriptionPopoutWindowStatus = {
        ...action.payload,
      };
    },
    setFetchingSuggestedIndicatorsStatus: (
      state,
      action: PayloadAction<{ isLoading: boolean }>
    ) => {
      state.suggestedIndicatorsStatus = {
        ...action.payload,
      };
    },
    setIsRefreshingDisplay: (state, action: PayloadAction<boolean>) => {
      state.isRefreshingDisplay = action.payload;
    },
    setIsEditMode: (state, action: PayloadAction<boolean>) => {
      state.isEditMode = action.payload;
    },
    setFetchingSuggestedAnswersStatus: (
      state,
      action: PayloadAction<{
        [key: string]: {
          isLoading?: boolean;
          hasError?: boolean;
          hasSucceeded?: boolean;
        };
      }>
    ) => {
      state.suggestedAnswersFetchingStatus = {
        ...state.suggestedAnswersFetchingStatus,
        ...action.payload,
      };
    },
    emptySuggestedAnswersStatus: (state) => {
      state.suggestedAnswersFetchingStatus = {};
    },
    setHasRequestedAnswers: (state, action: PayloadAction<boolean>) => {
      state.hasRequestedSuggestedAnswers = action.payload;
    },
    setHasQuestionnaireBeenCleared: (state, action: PayloadAction<boolean>) => {
      state.hasQuestionnaireBeenCleared = action.payload;
    },
    updateScrollPositions: (
      state,
      action: PayloadAction<{ [key: string]: number }>
    ) => {
      state.scrollPositions = {
        ...state.scrollPositions,
        ...action.payload,
      };
    },
    setCaseClosureId: (state, action: PayloadAction<string | null>) => {
      state.caseClosureId = action.payload;
    },
    setIsClearCaseDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpenClearCaseDialog = action.payload;
    },
    setIsDescriptionVisible: (state, action: PayloadAction<boolean>) => {
      state.isDescriptionVisible = action.payload;
    },
    setIsConfirmingCaseClosure: (state, action: PayloadAction<boolean>) => {
      state.isConfirmingCaseClosure = action.payload;
    },
    setIsConfirmingFinishQuestionnaire: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isConfirmingFinishQuestionnaire = action.payload;
    },
    setIsConfirmingReopenCase: (state, action: PayloadAction<boolean>) => {
      state.isConfirmingReopenCaseClosure = action.payload;
    },
    setIsConfirmingReopenQuestionnaire: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isConfirmingReopenQuestionnaire = action.payload;
    },
    setIsConfirmingDeleteIncident: (
      state,
      action: PayloadAction<{
        isOpen: boolean;
        incidentId: string;
      }>
    ) => {
      state.hasTriggeredConfirmIncidentDeleteDialog = action.payload;
    },
    setIsClosureSummaryDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isClosureSummaryDialogOpen = action.payload;
    },
    setPendingInterceptAction: (
      state,
      action: PayloadAction<PendingInterceptAction[]>
    ) => {
      state.pendingInterceptAction = action.payload;
    },
    setIsQuestionnaireTimeDialogOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isOpenQuestionnaireTimeDialog = action.payload;
    },
    setHasDismissedQuestionnaireTimeDialog: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.hasDismissedQuestionnaireTimeDialog = action.payload;
    },
    setActivePath: (state, action: PayloadAction<string>) => {
      state.activePath = action.payload;
    },
    setIsCaseClosureTimeDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpenCaseClosureTimeDialog = action.payload;
    },
    setHasDismissedCaseClosureTimeDialog: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.hasDismissedCaseClosureTimeDialog = action.payload;
    },
    setHasTriggeredIntegrationDialogOpen: (
      state,
      action: PayloadAction<{ isOpen: boolean; integrationName: string }>
    ) => {
      state.hasTriggeredIntegrationDialogOpen = action.payload;
    },
    setIsOpenCreateIncidentDialog: (state, action: PayloadAction<boolean>) => {
      state.isOpenCreateIncidentDialog = action.payload;
    },
    setIsContributorsListDialogOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isContributorsListDialogOpen = action.payload;
    },
    setIsCreatingIncident: (state, action: PayloadAction<boolean>) => {
      state.isCreatingIncident = action.payload;
    },
    setIsCreatingUser: (state, action: PayloadAction<boolean>) => {
      state.isCreatingUser = action.payload;
    },
    setIsOpenCreateUserFormDialog: (state, action: PayloadAction<boolean>) => {
      state.isOpenCreateUserFormDialog = action.payload;
    },
    setIsOpenCreateOrganisationDialog: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isOpenCreateOrganisationDialog = action.payload;
    },
    setOrganisationDialogState: (
      state,
      action: PayloadAction<{
        isEditMode: boolean;
        isOpen: boolean;
        organisationId?: string;
      }>
    ) => {
      state.organisationDialog = action.payload;
    },
    setIsCreatingOrganisation: (state, action: PayloadAction<boolean>) => {
      state.isCreatingOrganisation = action.payload;
    },
    setIsAddContributorModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isAddContributorModalOpen = action.payload;
    },
  },
});

export const {
  setRefreshDialogStatus,
  setPrintableSummaryStatus,
  setSnackbarNotification,
  removeSnackbarNotification,
  setDescriptionPopoutWindowStatus,
  setFetchingSuggestedIndicatorsStatus,
  setIsRefreshingDisplay,
  setIsEditMode,
  setFetchingSuggestedAnswersStatus,
  emptySuggestedAnswersStatus,
  setHasRequestedAnswers,
  setHasQuestionnaireBeenCleared,
  updateScrollPositions,
  setCaseClosureId,
  setIsClearCaseDialogOpen,
  setIsDescriptionVisible,
  setIsConfirmingCaseClosure,
  setIsConfirmingReopenCase,
  setIsConfirmingReopenQuestionnaire,
  setPendingInterceptAction,
  setIsQuestionnaireTimeDialogOpen,
  setHasDismissedQuestionnaireTimeDialog,
  setActivePath,
  setIsCaseClosureTimeDialogOpen,
  setHasDismissedCaseClosureTimeDialog,
  setHasTriggeredIntegrationDialogOpen,
  setIsClosureSummaryDialogOpen,
  setIsConfirmingFinishQuestionnaire,
  setIsOpenCreateIncidentDialog,
  setIsContributorsListDialogOpen,
  setIsCreatingIncident,
  setIsCreatingUser,
  setIsOpenCreateUserFormDialog,
  setIsOpenCreateOrganisationDialog,
  setIsCreatingOrganisation,
  setIsAddContributorModalOpen,
  setOrganisationDialogState,
  setIsConfirmingDeleteIncident,
} = uiSlice.actions;

export const selectDescriptionPopoutWindowStatus = (state: RootState) =>
  state.ui.descriptionPopoutWindowStatus.isOpen;
export const selectPrintableSummaryStatus = (state: RootState) =>
  state.ui.printableSummaryStatus;
export const selectSnackbarNotification = (state: RootState) =>
  state.ui.snackbarNotification;
export const selectRefreshDialogStatus = (state: RootState) =>
  state.ui.refreshDialogStatus;
export const selectSuggestedIndicatorsStatus = (state: RootState) =>
  state.ui.suggestedIndicatorsStatus;
export const selectIsRefreshingDisplay = (state: RootState) =>
  state.ui.isRefreshingDisplay;
export const selectIsEditMode = (state: RootState) => state.ui.isEditMode;
export const selectFetchingSuggestedAnswerStatus = (
  state: RootState,
  moduleId: string
) => state.ui.suggestedAnswersFetchingStatus?.[moduleId] ?? {};
export const selectHasRequestedAnswers = (state: RootState) =>
  state.ui.hasRequestedSuggestedAnswers;
export const selectScrollPositions = (state: RootState) =>
  state.ui.scrollPositions;
export const selectIsDescriptionVisible = (state: RootState) =>
  state.ui.isDescriptionVisible;
export const selectPendintInterceptAction = (state: RootState) =>
  state.ui.pendingInterceptAction;
export const selectActivePath = (state: RootState) => state.ui.activePath;
export const selectHasTriggeredIntegrationDialogOpen = (state: RootState) =>
  state.ui.hasTriggeredIntegrationDialogOpen;

// Questionnaire selectors
export const selectHasQuestionnaireBeenCleared = (state: RootState) =>
  state.ui.hasQuestionnaireBeenCleared;
export const selectIsConfirmingFinishQuestionnaire = (state: RootState) =>
  state.ui.isConfirmingFinishQuestionnaire;
export const selectIsConfirmingReopeningQuestionnaire = (state: RootState) =>
  state.ui.isConfirmingReopenQuestionnaire;
export const selectIsOpenQuestionnaireTimeDialog = (state: RootState) =>
  state.ui.isOpenQuestionnaireTimeDialog;
export const selectHasDismissedQuestionnaireTimeDialog = (state: RootState) =>
  state.ui.hasDismissedQuestionnaireTimeDialog;
// ************************

// Case closure selectors
export const selectIsClearCaseDialogOpen = (state: RootState) =>
  state.ui.isOpenClearCaseDialog;
export const selectCaseClosureId = (state: RootState) => state.ui.caseClosureId;
export const selectIsReopeningCase = (state: RootState) =>
  state.ui.isConfirmingReopenCaseClosure;
export const selectIsConfirmingCaseClosure = (state: RootState) =>
  state.ui.isConfirmingCaseClosure;
export const selectIsOpenCaseClosureTimeDialog = (state: RootState) =>
  state.ui.isOpenCaseClosureTimeDialog;
export const selectIsClosureSummaryDialogOpen = (state: RootState) =>
  state.ui.isClosureSummaryDialogOpen;
export const selectHasDismissedCaseClosureTimeDialog = (state: RootState) =>
  state.ui.hasDismissedCaseClosureTimeDialog;
// ************************

// ************************
// Admin selectors
// ***

// Incident selectors
export const selectIsCreatingIncident = (state: RootState) =>
  state.ui.isCreatingIncident;
export const selectIsOpenCreateIncidentDialog = (state: RootState) =>
  state.ui.isOpenCreateIncidentDialog;

export const selectIsConfirmingIncidentDeletionDialog = (state: RootState) =>
  state.ui.hasTriggeredConfirmIncidentDeleteDialog;

// Contributor selectors
export const selectIsContributorsListDialogOpen = (state: RootState) =>
  state.ui.isContributorsListDialogOpen;

// User selectors
export const selectIsCreatingUser = (state: RootState) =>
  state.ui.isCreatingUser;
export const selectIsOpenCreateUserFormDialog = (state: RootState) =>
  state.ui.isOpenCreateUserFormDialog;

// Organisation selectors
export const selectIsOpenCreateOrganisationDialog = (state: RootState) =>
  state.ui.isOpenCreateOrganisationDialog;

export const selectIsCreatingOrganisation = (state: RootState) =>
  state.ui.isCreatingOrganisation;
export const selectIsAddContributorModalOpen = (state: RootState) =>
  state.ui.isAddContributorModalOpen;
export const selectIsOrganisationDialogState = (state: RootState) =>
  state.ui.organisationDialog;

// ************************

export default uiSlice.reducer;
