import { useAppSelector } from '@/hooks';
import { selectQuestionValue } from '@/store/slices/questions';
import { QuestionOptions } from '@/store/types/question';
import { QuestionId } from '@/store/types/basicTypes';
import { FormControl } from '@mui/material';
import { TextField } from '@s-rm/react-ui-lib';
import Autocomplete from '@mui/material/Autocomplete';

export interface SelectProps {
  options: QuestionOptions[];
  onChange: (val: string) => void;
  questionId: QuestionId;
  'data-testid': string;
}

const Select = ({
  options,
  onChange,
  questionId,
  'data-testid': testId,
}: SelectProps) => {
  const value = useAppSelector((state) =>
    selectQuestionValue(state, questionId)
  );

  return (
    <FormControl sx={{ width: '100%', maxWidth: '400px' }}>
      <Autocomplete
        data-testid={testId}
        id='autocomplete-select'
        options={options}
        getOptionLabel={(option) => option?.label}
        value={options.find((option) => option?.value === value)}
        fullWidth={true}
        renderInput={(params) => (
          <TextField {...params} fullWidth size='small' />
        )}
        onChange={(e, o) => onChange(o?.value ?? '')}
      />
    </FormControl>
  );
};

export default Select;
