import { AdviceSlice } from './types/advice';
import { EntityType } from './types/basicTypes';
import { Questionnaire } from './types/questionnaire';
import { CaseTypeSlice } from './types/caseType';
import { ConfigSlice } from './types/config';
import EditorSlice from './types/editor';
import { IndicatorSlice } from './types/indicator';
import { IntegrationSlice } from './types/integrations';
import { ModuleSlice } from './types/module';
import { QuestionSlice } from './types/question';
import { QuestionDisplayOrderSlice } from './types/questionDisplayOrder';
import { TipSlice } from './types/tip';
import { SnackbarTypes, UiSlice } from './types/ui';
import { AdminSlice, defaultFiltersObject, UserStatus } from './types/admin';

export interface InitialState {
  caseTypes: CaseTypeSlice;
  indicators: IndicatorSlice;
  questions: QuestionSlice;
  tips: TipSlice;
  advice: AdviceSlice;
  modules: ModuleSlice;
  ui: UiSlice;
  config: ConfigSlice;
  questionDisplayOrder: QuestionDisplayOrderSlice;
  editor: EditorSlice;
  integrations: IntegrationSlice;
  questionnaire: Questionnaire;
  admin: AdminSlice;
}

export const initialState: InitialState = {
  config: {
    hasInitialised: false,
    isCaseValid: false,
    errors: [],
    user: {
      id: '',
      name: '',
      email: '',
      roles: [],
      status: UserStatus.Active,
    },
    featureFlags: {},
    accessPermissions: [],
  },
  ui: {
    scrollPositions: {},
    printableSummaryStatus: {
      isOpen: false,
      isAutoCopy: false,
    },
    snackbarNotification: {
      message: null,
      action: null,
      type: SnackbarTypes.Info,
      disableAutoHide: false,
    },
    refreshDialogStatus: {
      isOpen: false,
    },
    descriptionPopoutWindowStatus: {
      isOpen: false,
    },
    suggestedIndicatorsStatus: {
      isLoading: false,
    },
    isRefreshingDisplay: false,
    isEditMode: false,
    suggestedAnswersFetchingStatus: {},
    hasRequestedSuggestedAnswers: false,
    hasQuestionnaireBeenCleared: false,
    hasDismissedQuestionnaireTimeDialog: false,
    caseClosureId: null,
    isOpenClearCaseDialog: false,
    isDescriptionVisible: false,
    isConfirmingCaseClosure: false,
    isConfirmingFinishQuestionnaire: false,
    isConfirmingReopenCaseClosure: false,
    isConfirmingReopenQuestionnaire: false,
    isClosureSummaryDialogOpen: false,
    pendingInterceptAction: [],
    isOpenQuestionnaireTimeDialog: false,
    activePath: '',
    isOpenCaseClosureTimeDialog: false,
    hasDismissedCaseClosureTimeDialog: false,
    hasTriggeredIntegrationDialogOpen: {
      isOpen: false,
      integrationName: '',
    },
    isOpenCreateIncidentDialog: false,
    isContributorsListDialogOpen: false,
    isCreatingIncident: false,
    isCreatingUser: false,
    isOpenCreateUserFormDialog: false,
    isOpenCreateOrganisationDialog: false,
    isCreatingOrganisation: false,
    isCreateUserModalOpen: false,
    isAddContributorModalOpen: false,
    organisationDialog: {
      isOpen: false,
      isEditMode: false,
      organisationId: '',
    },
    hasTriggeredConfirmIncidentDeleteDialog: {
      isOpen: false,
      incidentId: '',
    },
  },
  editor: {
    isDirty: false,
    questionInEdit: {
      id: null,
      isNew: false,
    },
    moduleInEdit: {
      id: null,
      isNew: false,
    },
    tipInEdit: {
      id: null,
      isNew: false,
    },
    caseTypeInEdit: {
      id: null,
      isNew: false,
    },
    moduleGroupInEdit: {
      id: null,
      isNew: false,
    },
    adviceInEdit: {
      id: null,
      isNew: false,
    },
    activeEntitySearchList: null,
    activeParentTreeView: {
      entityType: null,
      entityId: null,
    },
    entitySearchFilter: {
      searchTerm: '',
      filterBy: EntityType.Question,
      entityIds: [],
    },
    isSessionModalOpen: false,
    isConfrimUseEditorModalOpen: false,
    sessions: {
      isFetching: true,
      entities: {},
      ids: [],
    },
    sessionInEdit: null,
    entityModifications: {
      caseTypes: {},
      modules: {},
      moduleGroups: {},
      questions: {},
      tips: {},
      advice: {},
      indicators: {},
    },
    outdatedEntities: {
      caseTypes: {},
      modules: {},
      moduleGroups: {},
      questions: {},
      tips: {},
      advice: {},
      indicators: {},
    },
    resetAndSyncEntityModal: {
      isOpen: false,
      entityId: null,
      entityType: null,
    },
  },
  questionDisplayOrder: {},
  questionnaire: {
    questionnaireId: '',
    projectId: '',
    id: '',
    description: '',
    descriptionPreSuggestedIndicators: '',
    client: {
      name: '',
    },
    project: {
      name: '',
    },
    isActive: false,
    isIntakeActive: true, // To be depricated and replaced -> isActive
    intakeTime: '', // To be depricated and replaced -> startedAt
    closureTime: null, // To be depricated and replaced -> completedAt
    triggeredIntegrations: [],
    isDiagnosisEnabled: true,
    isNotesEnabled: false,
    isAddModulesEnabled: false,
    completedAt: null,
    startedAt: null,
    label: '',
  },
  caseTypes: {
    entities: {},
    ids: [],
    suggested: [],
    selected: [],
    isLoading: false,
  },
  modules: {
    entities: {},
    ids: [],
    groups: {
      entities: {},
      ids: [],
    },
    selected: [],
    manuallySelected: [],
    selectedByTrigger: [],
    staticModuleIds: [],
  },
  indicators: {
    entities: {},
    ids: [],
    selected: [],
    dismissed: [],
    indicatorCaseTypesMap: {},
    suggested: [],
  },
  questions: {
    entities: {},
    questionDisplayOrder: {},
    questionsVisibility: {},
    values: {},
    ids: [],
    versions: {},
    suggestedValues: {},
  },
  tips: {
    entities: {},
    ids: [],
    advice: [],
    aliasMap: {},
  },
  advice: {
    entities: {},
    ids: [],
  },
  integrations: {
    entities: {},
    ids: [],
  },
  admin: {
    questionnaires: {
      entities: {},
      ids: [],
    },
    incidents: {
      ids: [],
      entities: {},
      filters: defaultFiltersObject,
      isLoading: false,
    },
    contributors: {
      entities: {},
      ids: [],
      filters: defaultFiltersObject,
      isLoading: false,
    },
    organisations: {
      entities: {},
      ids: [],
      filters: defaultFiltersObject,
      isLoading: false,
      isUpdating: false,
      recentlyCreatedOrgFields: {},
      orgCreationDestination: '',
    },
    users: {
      ids: [],
      entities: {},
      filters: defaultFiltersObject,
      isLoading: false,
    },
  },
};

export default initialState;
