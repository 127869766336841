import { defaultFiltersObject, User, UserStatus } from '@/store/types/admin';
import { UserRoleNames } from '@/store/types/config';

export const mockUser1: User = {
  id: '5df601a7-9875-4656-977e-ce0de45ca0ad',
  email: 'alice.johnson@example.com',
  name: 'Alice Johnson',
  organisation: {
    id: '5df601a7-9875-4656-977e-ce0de45ca0ad',
    name: 'Enterprise Solutions',
    status: 'active',
  },
  status: UserStatus.Active,
  roles: [UserRoleNames['POLUS-RESPONSE:ADMIN']],
  createdAt: '12.09.2024',
};

export const mockUser2: User = {
  id: '1002',
  email: 'charlie.williams@example.org',
  name: 'Charlie Williams',
  organisation: {
    id: '5df601a7-9875-4656-977e-ce0de45ca0ad',
    name: 'Enterprise Solutions',
    status: 'active',
  },
  status: UserStatus.Invited,
  roles: [UserRoleNames['POLUS-RESPONSE:USER']],
  createdAt: '12.09.2024',
};

export const mockUser3: User = {
  id: '1003',
  email: 'david.smith@example.org',
  name: 'David Smith',
  organisation: {
    id: '5df601a7-9875-4656-977e-ce0de45ca0ad',
    name: 'Enterprise Solutions',
    status: 'active',
  },
  status: UserStatus.Invited,
  roles: [UserRoleNames['POLUS-RESPONSE:ADMIN']],
  createdAt: '12.09.2024',
};

export const mockUser4: User = {
  id: '1004',
  email: 'emily.brown@example.org',
  name: 'Emily Brown',
  organisation: {
    id: '5df601a7-9875-4656-977e-ce0de45ca0ad',
    name: 'Enterprise Solutions',
    status: 'active',
  },
  status: UserStatus.Invited,
  roles: [UserRoleNames['POLUS-RESPONSE:ADMIN']],
  createdAt: '12.09.2024',
};

export const mockUser5: User = {
  id: '1005',
  email: 'frank.jones@example.org',
  name: 'Frank Jones',
  organisation: {
    id: '5df601a7-9875-4656-977e-ce0de45ca0ad',
    name: 'Enterprise Solutions',
    status: 'active',
  },
  status: UserStatus.Suspended,
  roles: [UserRoleNames['POLUS-RESPONSE:ADMIN']],
  createdAt: '12.09.2024',
};

export const mockUser6: User = {
  id: '1006',
  email: 'sophia.davis@example.com',
  name: 'Sophia Davis',
  organisation: {
    id: '5df601a7-9875-4656-977e-ce0de45ca0ad',
    name: 'Enterprise Solutions',
    status: 'active',
  },
  status: UserStatus.Active,
  roles: [UserRoleNames['POLUS-RESPONSE:ADMIN']],
  createdAt: '12.09.2024',
};

export const mockUser7: User = {
  id: '1007',
  email: 'henry.martin@example.org',
  name: 'Henry Martin',
  organisation: {
    id: '5df601a7-9875-4656-977e-ce0de45ca0ad',
    name: 'Enterprise Solutions',
    status: 'active',
  },
  status: UserStatus.Invited,
  roles: [UserRoleNames['POLUS-RESPONSE:USER']],
  createdAt: '12.09.2024',
};

export const mockUser8: User = {
  id: '1008',
  email: 'isabella.moore@example.org',
  name: 'Isabella Moore',
  organisation: {
    id: '5df601a7-9875-4656-977e-ce0de45ca0ad',
    name: 'Enterprise Solutions',
    status: 'active',
  },
  status: UserStatus.Invited,
  roles: [UserRoleNames['POLUS-RESPONSE:ADMIN']],
  createdAt: '12.09.2024',
};

export const mockUser9: User = {
  id: '1009',
  email: 'james.white@example.org',
  name: 'James White',
  organisation: {
    id: '5df601a7-9875-4656-977e-ce0de45ca0ad',
    name: 'Enterprise Solutions',
    status: 'active',
  },
  status: UserStatus.Invited,
  roles: [UserRoleNames['POLUS-RESPONSE:ADMIN']],
  createdAt: '12.09.2024',
};

export const mockUser10: User = {
  id: '1010',
  email: 'karen.thomas@example.org',
  name: 'Karen Thomas',
  organisation: {
    id: '5df601a7-9875-4656-977e-ce0de45ca0ad',
    name: 'Enterprise Solutions',
    status: 'active',
  },
  status: UserStatus.Suspended,
  roles: [UserRoleNames['POLUS-RESPONSE:ADMIN']],
  createdAt: '12.09.2024',
};

export const mockUser11: User = {
  id: '1011',
  email: 'lucas.miller@example.org',
  name: 'Lucas Miller',
  organisation: {
    id: '5df601a7-9875-4656-977e-ce0de45ca0ad',
    name: 'Enterprise Solutions',
    status: 'active',
  },
  status: UserStatus.Suspended,
  roles: [UserRoleNames['POLUS-RESPONSE:ADMIN']],
  createdAt: '12.09.2024',
};

export const mockUsers = {
  entities: {
    [mockUser1.id]: mockUser1,
  },
  ids: [mockUser1.id],
  filters: defaultFiltersObject,
  isLoading: false,
};
