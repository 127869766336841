import { useAppSelector } from '@/hooks';
import {
  selectActiveModuleIds,
  selectModuleGroups,
  selectStaticModuleIds,
  selectTriggeredModuleIds,
} from '@/store/slices/modules';
import {
  selectCaseTypeEntities,
  selectSelectedCaseTypeIds,
} from '@/store/slices/caseTypes';
import { selectIsDiagnosisEnabled } from '@/store/slices/questionnaire';
import getDiagnosisModuleDisplayStructure from '@/components/utils/getDiagnosisModuleDisplayStructure';
import { getStaticModuleDisplayStructure } from '@/components/utils/getStaticModuleDisplayStructure';

const useModuleDisplayStructure = () => {
  const selectedModuleIds = useAppSelector(selectActiveModuleIds);
  const caseTypeEntities = useAppSelector(selectCaseTypeEntities);
  const moduleGroups = useAppSelector(selectModuleGroups);
  const selectedCaseTypeIds = useAppSelector(selectSelectedCaseTypeIds);
  const triggeredModules = useAppSelector(selectTriggeredModuleIds);
  const staticModuleIds = useAppSelector(selectStaticModuleIds);
  const isUsingDiagnosis = useAppSelector(selectIsDiagnosisEnabled);

  return isUsingDiagnosis
    ? getDiagnosisModuleDisplayStructure({
        caseTypes: caseTypeEntities,
        selectedModuleIds,
        moduleGroups,
        selectedCaseTypeIds: selectedCaseTypeIds,
        triggeredModules,
      })
    : getStaticModuleDisplayStructure({
        moduleGroups,
        staticModuleIds,
        triggeredModules,
      });
};

export default useModuleDisplayStructure;
