import { PayloadAction } from '@reduxjs/toolkit';

import {
  AdminSlice,
  defaultFiltersObject,
  Filters,
  User,
} from '@/store/types/admin';
import {
  UpdateFilterPayload,
  updateFilters,
} from '@/store/utils/updateFilters';

const usersReducer = {
  setUsers: (state: AdminSlice, action: PayloadAction<User[]>) => {
    state.users.entities = action.payload.reduce((acc, user) => {
      acc[user.id] = user;
      return acc;
    }, {} as Record<string, User>);
    state.users.ids = action.payload.map((v) => v.id);
  },
  addUsers: (state: AdminSlice, action: PayloadAction<User[]>) => {
    state.users.entities = action.payload.reduce(
      (acc, user) => {
        acc[user.id] = user;
        return acc;
      },
      { ...state.users.entities }
    );
    state.users.ids = Array.from(
      new Set([...state.users.ids, ...action.payload.map((v) => v.id)])
    );
  },
  updateUsersFilters: (
    state: AdminSlice,
    action: PayloadAction<UpdateFilterPayload>
  ) => {
    state.users.filters = updateFilters(state.users.filters, action.payload);
  },
  setUsersFilters: (
    state: AdminSlice,
    action: PayloadAction<Partial<Filters>>
  ) => {
    state.users.filters = Object.keys(action.payload).reduce(
      (acc, key) => ({
        ...acc,
        [key]: action.payload[key as keyof Filters],
      }),
      state.users.filters
    );
  },
  setUsersSearchTerm: (state: AdminSlice, action: PayloadAction<string>) => {
    state.users.filters = {
      ...state.users.filters,
      searches: [
        {
          column: 'name',
          value: action.payload,
        },
      ],
    };
  },
  setIsLoadingUsers: (state: AdminSlice, action: PayloadAction<boolean>) => {
    state.users.isLoading = action.payload;
  },
  resetUsersFilters: (state: AdminSlice) => {
    state.users.filters = defaultFiltersObject;
  },
  updateUser: (
    state: AdminSlice,
    action: PayloadAction<{ id: string; updates: Partial<User> }>
  ) => {
    const { id, updates } = action.payload;
    state.users.entities[id] = {
      ...state.users.entities[id],
      ...updates,
    };
  },
};

export default usersReducer;
