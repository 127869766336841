import { ErrorCodes } from '@/constants/errors';
import { User } from './admin';

export interface AppError {
  error?: Error;
  code: ErrorCodes;
}

export enum AccessPermissions {
  ViewIncident = 'VIEW_INCIDENT',
  ViewIncidents = 'VIEW_INCIDENTS',
  ViewContributors = 'VIEW_CONTRIBUTORS',
  ViewOrganisations = 'VIEW_ORGANISATIONS',
  ViewQuestionnaires = 'VIEW_QUESTIONNAIRES',
  ViewClosure = 'VIEW_CLOSURE',
  ViewUserDetail = 'VIEW_USER_DETAIL',
  UpdateInsurerPrimaryContact = 'UPDATE_INSURER_PRIMARY_CONTACT',
  ViewUsersInCurrentOrg = 'VIEW_USERS_IN_CURRENT_ORG',
  ViewIncidentsPerUser = 'VIEW_INCIDENTS_PER_USER',
  ViewIncidentManagerOrgs = 'VIEW_INCIDENT_MANAGER_ORGS',
  UpdateIncidentManagerOrg = 'UPDATE_INCIDENT_MANAGER_ORG',
  UpdateIncidentManagerPrimaryContact = 'UPDATE_INCIDENT_MANAGER_PRIMARY_CONTACT',
  ViewIncidentByContributor = 'VIEW_INCIDENT_BY_CONTRIBUTOR',
  CreateIncident = 'CREATE_INCIDENT',
  ViewClosedClosure = 'VIEW_CLOSED_CLOSURE',
  ViewClosedIncident = 'VIEW_CLOSED_INCIDENT',
  ViewEditor = 'VIEW_EDITOR',
  SubmitClosure = 'SUBMIT_CLOSURE',
  SubmitIntake = 'SUBMIT_INTAKE',
  AccessOrganisations = 'ACCESS_ORGANISATIONS',
  AccessUsers = 'ACCESS_USERS',
  AccessContributors = 'ACCESS_CONTRIBUTORS',
  DeleteIncident = 'DELETE_INCIDENT',
}

export enum UserRoleNames {
  INSURER = 'INSURER',
  'INCIDENT-MANAGER' = 'INCIDENT-MANAGER',
  'POLUS-RESPONSE:ADMIN' = 'POLUS-RESPONSE:ADMIN',
  'POLUS-RESPONSE:USER' = 'POLUS-RESPONSE:USER',
  'INCIDENT-MANAGER-PRIMARY-CONTACT' = 'INCIDENT-MANAGER-PRIMARY-CONTACT',
  'INSURER-PRIMARY-CONTACT' = 'INSURER-PRIMARY-CONTACT',
  'POLUS-RESPONSE:TEMP-EXTRA' = 'POLUS-RESPONSE:TEMP-EXTRA',
  CONTRIBUTOR = 'CONTRIBUTOR',
}

export interface ConfigSlice {
  hasInitialised: boolean;
  isCaseValid: boolean;
  errors: AppError[];
  user: User;
  featureFlags: Record<string, boolean>;
  accessPermissions: AccessPermissions[];
}
