'use client';
import { useRouter } from 'next/router';
import { useAppSelector } from './storeHooks';
import { getRootPathFromRouter } from '@/utils/router';
import { getIsClosure, getSandboxCaseId } from '@/utils/helpers';
import { selectQuestionnaireLabel } from '@/store/slices/questionnaire';

const useEnterOrExitEditorRedirect = () => {
  const questionnaireLabel = useAppSelector(selectQuestionnaireLabel);
  const router = useRouter();
  const isEditMode = router?.pathname?.includes('edit');
  const questionnaireType = getIsClosure(questionnaireLabel)
    ? 'closure'
    : 'intake';

  return () =>
    router.isReady &&
    (window.location.href = `/${getRootPathFromRouter(
      router
    )}/${getSandboxCaseId(questionnaireType)}${isEditMode ? '' : '/edit'}`);
};

export default useEnterOrExitEditorRedirect;
