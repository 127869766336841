import { UserRoleNames } from '@/store/types/config';
import { Option } from '@s-rm/react-ui-lib/lib/components/MultiSelectSearch/MultiSelectSearch';

export interface FormValues {
  organisation: Option;
  email: string;
  name: string;
  userRole: string[];
}

export const defaultFormValues: FormValues = {
  organisation: {
    value: '',
    label: '',
    secondaryLabel: '',
  },
  email: '',
  name: '',
  userRole: [],
};

export interface OrganisationFromAPI {
  id: string;
  name: string;
}

export const userRoleOptions = [
  { value: UserRoleNames['POLUS-RESPONSE:USER'], label: 'User' },
  { value: UserRoleNames['POLUS-RESPONSE:ADMIN'], label: 'Admin' },
];

export interface CreateUserFormCallbackParams {
  id: string;
  name: string;
  email: string;
  userRole: string[];
  organisation?: OrganisationFromAPI;
}
