import { makeRequest } from '@/api/axios/client';
import { getFormattedUsers } from '@/api/transforms/admin/getFormattedUsers';
import { Filters } from '@/store/types/admin';

const getAllUsers = async ({
  limit,
  offset,
  sortColumn = 'createdAt',
  sortDirection = 'desc',
  filters,
}: {
  limit: number;
  offset: number;
  sortColumn?: string;
  sortDirection?: string;
  filters?: Filters['filters'];
}) => {
  const { data, error } = await makeRequest({
    method: 'POST',
    url: `${
      process.env.NEXT_PUBLIC_REST_API_URL
    }/users/search?sortColumn=${sortColumn}&sortDirection=${
      sortDirection?.toUpperCase() || 'DESC'
    }`,
    data: {
      pagination: {
        limit,
        offset,
      },
      ...(filters && { filters }),
    },
  });

  return {
    data: error
      ? null
      : {
          data: getFormattedUsers(data.data!),
          pagination: data.pagination,
        },
    error,
  };
};

export default getAllUsers;
