import ConfirmationModal from '@/components/ConfirmationModal';
import { useAppDispatch, useAppSelector } from '@/hooks';
import useEnterOrExitEditorRedirect from '@/hooks/useEnterOrExitEditorRedirect';
import {
  selectIsConfirmUseEditorModalOpen,
  setIsConfrimUseEditorModalOpen,
} from '@/store/slices/editor';

import { Alert, AlertTitle, Typography } from '@mui/material';
import { useRouter } from 'next/router';

const ConfirmUseEditorModal = () => {
  const isOpen = useAppSelector(selectIsConfirmUseEditorModalOpen);
  const dispatch = useAppDispatch();
  const onClose = () => dispatch(setIsConfrimUseEditorModalOpen(false));
  const router = useRouter();
  const isEditMode = router.pathname.includes('edit');
  const exitEditor = useEnterOrExitEditorRedirect();

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title={isEditMode ? 'Exit Editor' : 'Enter Editor'}
      onClose={onClose}
      onConfirm={exitEditor}
    >
      <Alert severity='info'>
        {isEditMode && (
          <AlertTitle>
            You are now exiting the editor and will return to the default
            sandbox case
          </AlertTitle>
        )}
        {!isEditMode && (
          <>
            <AlertTitle>
              <b>You are now entering a sandbox case.</b>
            </AlertTitle>
            <Typography>
              This will take you away from your current case, and allow you to
              use the editor.
            </Typography>
          </>
        )}
      </Alert>
    </ConfirmationModal>
  );
};

export default ConfirmUseEditorModal;
