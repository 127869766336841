import updateQuestionnaire from '@/api/mutations/updateQuestionnaire';
import {
  setIsQuestionnaireActive,
  setQuestionnaireCompletedAt,
} from '../slices/questionnaire';
import { AppThunk } from '../store';

const submitCase = (): AppThunk => async (dispatch) => {
  const dateNow = new Date().toISOString();
  dispatch(setIsQuestionnaireActive(false));
  // Note: Ideally the completedAt date should be set
  // by the backend i.e after the response is received update completedAt state.
  dispatch(setQuestionnaireCompletedAt(dateNow));
  await updateQuestionnaire();
};

export default submitCase;
