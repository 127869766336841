import { ErrorCodes } from '@/constants/errors';
import { createError } from '@/utils/errorHandler';
import { makeRequest } from '../axios/client';

const handleLogout = () => {
  const errorHandler = (e?: any) =>
    createError({
      code: ErrorCodes.GetAllUsers,
      displaySnackbar: true,
      error: e,
    });
  try {
    makeRequest({
      url: `${process.env.NEXT_PUBLIC_AUTH_URL}/logout?redirectUrl=${window.location.origin}/incidents`,
      method: 'GET',
    }).then(({ data }) => {
      if (data['redirect_uri']) {
        window.location.href = data['redirect_uri'];
      } else {
        errorHandler();
      }
    });
  } catch (e) {
    errorHandler(e);
  }
};

export default handleLogout;
