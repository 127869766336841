import {
  setIsLoadingContributors,
  selectContributorsFilters,
  updateContributorFilters,
  setContributors,
} from '@/store/slices/admin/admin';
import { AppThunk } from '../../store';
import fetchContributors from '@/api/admin/contributors/fetchContributors';
import updateSnackbarNotification from '../updateSnackbar';
import { SnackbarTypes } from '@s-rm/react-ui-lib';

const fetchContributorsThunk =
  ({ incidentId }: { incidentId: string }): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setIsLoadingContributors(true));
    const filters = selectContributorsFilters(getState());

    const { data, error } = await fetchContributors({
      filters,
      incidentId,
    });

    if (error) {
      dispatch(
        updateSnackbarNotification({
          message: 'An error occurred while fetching contributors',
          type: SnackbarTypes.Error,
        })
      );
    } else {
      dispatch(setContributors(data!.data));

      dispatch(
        updateContributorFilters({
          pagination: {
            offset: filters.pagination?.offset || 0,
            limit: data!.pagination.limit,
            total: data!.pagination.total,
          },
        })
      );
    }

    dispatch(setIsLoadingContributors(false));
  };

export default fetchContributorsThunk;
