import { Question, QuestionAnswerValue } from '@/store/types/question';

export const getVisibleMandatoryQuestions = (
  visibleQuestions: Record<string, boolean> = {},
  questionEntities: Record<string, Question> = {}
): string[] => {
  return Object.keys(visibleQuestions).filter(
    (id) => visibleQuestions[id] && questionEntities[id]?.validation?.isRequired
  );
};

export const getAnsweredVisibleMandatoryQuestions = (
  visibleMandatoryQuestions: string[],
  questionValues: Record<string, QuestionAnswerValue> = {}
): number => {
  return visibleMandatoryQuestions.filter((id) => !!questionValues[id]).length;
};
