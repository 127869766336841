import { RawUsersApiData } from '@/api/admin/user/types';
import { User, UserStatus } from '@/store/types/admin';

export const getFormattedUsers = (data: RawUsersApiData[]): User[] => {
  return data.map((org) => {
    return {
      id: org.id,
      name: org.name,
      email: org.email,
      organisation: {
        id: org.organisation?.id || '',
        name: org.organisation?.name || '',
      },
      status: org.status || UserStatus.Active,
      roles: org.role_names || [],
    };
  });
};

export const getFormattedUsersInOrganisation = (data: any[]): User[] => {
  return data.map((org) => {
    return {
      id: org.id,
      name: org.name,
      email: org.email,
      organisation: org.organisation.name,
      status: org.status,
      roles: org.role_names || [],
    };
  });
};
