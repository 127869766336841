import useRequiredQuestionsCounter from './hooks/useRequiredQuestionsCounter';
import { Counter } from './styled';
import { Box, Typography } from '@mui/material';
import useGetModulesWithGroups from '@/hooks/useGetModulesWithGroups';
import { useAppSelector } from '@/hooks';
import {
  selectStaticModuleIds,
  selectTriggeredModuleIds,
} from '@/store/slices/modules';

const GlobalQuestionCounter = () => {
  const moduleIds = useGetModulesWithGroups();
  const staticModuleIds = useAppSelector(selectStaticModuleIds);
  const triggeredModules = useAppSelector(selectTriggeredModuleIds)?.map(
    (m) => m.moduleId
  );
  const isUsingStaticModules = !!staticModuleIds?.length;
  const { total, answered, isComplete } = useRequiredQuestionsCounter(
    isUsingStaticModules ? [...staticModuleIds, ...triggeredModules] : moduleIds
  ).combinedCounters;

  if (!total) {
    return null;
  }
  return (
    <Counter isComplete={isComplete}>
      <div>
        <Typography component='span' mr={1} color='pal'>
          Required questions
        </Typography>
        <span data-testid={`global-required-questions-answered`}>
          {answered}
        </span>
        <span>/</span>
        <span data-testid={`global-total-required-questions`}>{total}</span>
      </div>
      <Box
        fontSize='1.2rem'
        fontWeight={600}
        lineHeight={0}
        color={isComplete ? 'primary.main' : 'error.main'}
        ml={'-5px'}
      >
        *
      </Box>
    </Counter>
  );
};

export default GlobalQuestionCounter;
