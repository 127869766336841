import useAuthCheck from '@/hooks/useAuthCheck';
import AuthProviderContext from './AuthProviderContext';
import { ReactNode, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { CssBaseline } from '@mui/material';
import PageLoader from '../Placeholder/PageLoader';
import { lightTheme } from '@s-rm/react-ui-lib';
import { LoadingBox } from './styled';

const PUBLIC_ROUTES = ['/login', '/unauthorized', '/logout'];

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isClient, setIsClient] = useState(false);
  const { userData, isLoading } = useAuthCheck();

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) return null;

  const isPublicRoute = PUBLIC_ROUTES.some((route) =>
    window.location.pathname.includes(route)
  );

  // For public routes, render immediately
  if (isPublicRoute) {
    return <>{children}</>;
  }

  // For protected routes, show loader until we have confirmed auth status
  if (isLoading || !userData) {
    return (
      <ThemeProvider theme={lightTheme}>
        <CssBaseline />
        <LoadingBox data-testid='auth-provider' height='100vh'>
          <PageLoader text='Authenticating...' />
        </LoadingBox>
      </ThemeProvider>
    );
  }

  // Only render children when we have confirmed user data
  return (
    <AuthProviderContext.Provider value={{ userData, isLoading }}>
      {children}
    </AuthProviderContext.Provider>
  );
};

export default AuthProvider;
