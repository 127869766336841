import getAllOrganisations from '@/api/admin/organisations/getAllOrganisations';

export const getOrganisationListItems = async ({
  limit = 10,
  offset,
  isNewSearch = false,
  searchTerm = '',
}: {
  limit: number;
  offset: number;
  isNewSearch?: boolean;
  searchTerm?: string;
}) => {
  const { data, error } = await getAllOrganisations({
    limit,
    offset: isNewSearch ? 0 : offset,
    filters: [
      {
        column: 'name',
        values: [searchTerm ?? ''],
      },
    ],
  });

  if (error) {
    return { newItems: [], total: 0 };
  }

  const newItems = data!.data.map((item) => ({
    id: item.id,
    name: item.name,
  }));

  return { newItems, total: data!.pagination.total };
};

export const orgOptionsFormatter = (items: { id: string; name: string }[]) => {
  return items.map((item) => ({
    value: item.id,
    label: item.name,
    secondaryLabel: '',
  }));
};
