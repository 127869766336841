import { makeRequest } from '@/api/axios/client';
import { IncidentDetailsRawResponse, IncidentDetailsResponse } from './types';
import { getFormattedIncident } from '@/api/transforms/admin/getFormattedIncidentDetails';

const getIncidentDetailsById = async (
  id: string
): Promise<IncidentDetailsResponse> => {
  const { data, error } = await makeRequest<IncidentDetailsRawResponse>({
    method: 'GET',
    url: `${process.env.NEXT_PUBLIC_REST_API_URL}/incidents/${id}`,
  });

  return {
    data: error ? null : getFormattedIncident(data!),
    error,
  };
};

export default getIncidentDetailsById;
