import { RouteObject, Routes, routeData } from '@/constants/routes';
import { NextRouter } from 'next/router';

export const getRootPathFromRouter = (router: NextRouter): Routes =>
  (router.pathname.split('/').filter(Boolean)[0] || '/') as Routes;

export const getRootPathRequiresCaseId = (router: NextRouter): boolean => {
  const route = getRootPathFromRouter(router);
  return !!routeData[route]?.requiresCaseId;
};

export const getRootPathRequiresQuestionnaireId = (
  router: NextRouter
): boolean => {
  const route = getRootPathFromRouter(router);
  return !!routeData[route]?.requiresQuestionnaireId;
};

export const getRouteFromPath = (path: Routes): RouteObject => {
  return routeData[path as keyof typeof routeData];
};

export const getPathRequiresAuthFromUrl = (path: Routes): boolean => {
  return !!getRouteFromPath(path)?.requiresAuth;
};
export const getRootPathFromUrl = (url: string): Routes => {
  return (url.split('/').filter(Boolean)[0] || '/') as Routes;
};

export const getIsFeatureFlagRoute = (router: NextRouter): boolean => {
  const route = getRootPathFromRouter(router);
  return route === Routes.FeatureFlags;
};

export const getIsCaseClosureRoute = (router: NextRouter): boolean => {
  const route = getRootPathFromRouter(router);
  return route === Routes.Closure;
};

export const getIsAdminRoute = (router: NextRouter): boolean => {
  const route = getRootPathFromRouter(router);
  return !!routeData[route]?.isAdminRoute;
};

export const replaceRootPathFromPathname = ({
  pathname,
  rootPath,
}: {
  pathname: string;
  rootPath: string;
}): string => {
  let paths = pathname.split('/');

  if (paths.length > 2) {
    paths[1] = rootPath;
  }

  return paths.join('/');
};
