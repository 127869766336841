import React, { useState } from 'react';
import {
  Button,
  InfinateScrollSearch,
  LoadingButton,
} from '@s-rm/react-ui-lib';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectIsOpenCreateUserFormDialog,
  setIsOpenCreateUserFormDialog,
} from '@/store/slices/ui';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { InputLabelRequired } from '../styled';
import { contributorRolesData } from '@/data/admin';
import { CreateUserFormDialog } from '../CreateUserFormDialog/CreateUserFormDialog';
import { ContributorFunction } from '@/store/types/admin';
import {
  contributorsOptionsFormatter,
  getContributorsListItems,
} from './utils';
import createUserThunk from '@/store/thunks/admin/createUserThunk';
import { CreateUserFormCallbackParams } from '../CreateUserFormDialog/types';

const defaultFormValues = {
  contributor: { value: '', label: '', secondaryLabel: '' },
  contributorRoles: [] as ContributorFunction[],
};

type FormKey = 'contributor' | 'contributorRoles';

const AssignContributorDialog = ({
  onAssign,
  isDialogOpen,
  onCancel,
}: {
  onAssign?: (params: {
    id: string;
    name: string;
    email: string;
    roles: ContributorFunction[];
  }) => void;
  isDialogOpen: boolean;
  onCancel: () => void;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [formValues, setFormValues] = useState(defaultFormValues);
  const isOpen = useAppSelector(selectIsOpenCreateUserFormDialog);

  const isFormValid =
    !!formValues.contributor.value && formValues.contributorRoles.length > 0;

  const handleFormChange = (key: FormKey, value: any) => {
    setFormValues((prev) => {
      const isContributorSame =
        key === 'contributor' && prev[key]?.value === value?.value;

      return {
        ...prev,
        [key]: value,
        ...(isContributorSame ? { contributorRoles: [] } : {}),
      };
    });
  };

  const handleOnClickAssignContributor = () => {
    if (isFormValid) {
      const { value, label, secondaryLabel } = formValues.contributor;
      const roles = formValues.contributorRoles;

      onAssign?.({
        id: value,
        name: label,
        email: secondaryLabel,
        roles,
      });

      setFormValues(defaultFormValues);
    }
  };

  const getDisplayLabel = (selected: any, placeholderValue: string = '') => {
    if (Array.isArray(selected)) {
      return selected.length > 0 ? selected.join(', ') : placeholderValue;
    }
    return selected?.label ? selected.label : placeholderValue;
  };

  const handleCreateNewUser = ({
    email,
    name,
    organisation,
  }: CreateUserFormCallbackParams) => {
    const cb = ({ id }: { id: string }) => {
      handleFormChange('contributor', {
        value: id,
        label: name,
        secondaryLabel: email,
      });
    };
    dispatch(
      createUserThunk({ organisation: organisation!.id!, email, name, cb })
    );
  };

  const handleClose = () => {
    setFormValues(defaultFormValues);
    onCancel();
  };

  return (
    <>
      <Dialog open={isDialogOpen} onClose={handleClose} maxWidth='sm' fullWidth>
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            variant='h3'
            sx={{ fontWeight: 700 }}
            data-testid='assign-contributor-dialog-title'
          >
            Assign a contributor
          </Typography>
          <IconButton aria-label='close' onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <InputLabelRequired shrink htmlFor='contributor' required>
            Select a contributor
          </InputLabelRequired>
          <FormControl fullWidth>
            <InfinateScrollSearch
              fetchItems={getContributorsListItems}
              optionsFormatter={contributorsOptionsFormatter}
              paginationLimit={10}
              placeholder={formValues.contributor?.label || 'Search for a user'}
              onChange={(value) => handleFormChange('contributor', value)}
              footerAction={() => dispatch(setIsOpenCreateUserFormDialog(true))}
              actionButtonText='Create new user'
            />
          </FormControl>

          <InputLabelRequired
            shrink
            htmlFor='contributor-role'
            required
            sx={{ mt: 2 }}
          >
            Role in current project
          </InputLabelRequired>
          <FormControl fullWidth>
            <Select
              data-testid={'select-contrib-role'}
              fullWidth
              multiple
              size='small'
              name='contributor-role'
              value={formValues.contributorRoles as string[]}
              onChange={(event) =>
                handleFormChange('contributorRoles', event.target.value)
              }
              disabled={!formValues.contributor.value}
              displayEmpty
              renderValue={(selected) => {
                if (!selected.length) {
                  return (
                    <Typography variant='body1'>
                      {getDisplayLabel(selected, 'Select a contributor role')}
                    </Typography>
                  );
                }
                return selected
                  .map(
                    (role) =>
                      contributorRolesData[role as ContributorFunction]?.label
                  )
                  .join(', ');
              }}
            >
              {Object.values(contributorRolesData).map(
                ({ abreviation, label }) => (
                  <MenuItem key={abreviation} value={abreviation}>
                    <Checkbox
                      checked={formValues.contributorRoles.includes(
                        abreviation as ContributorFunction
                      )}
                    />
                    <ListItemText primary={label} />
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions
          sx={{
            padding: 2,
            borderTop: `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <Button onClick={handleClose} variant='outlined'>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleOnClickAssignContributor}
            variant='contained'
            disabled={!isFormValid}
          >
            Assign
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <CreateUserFormDialog
        isContributor
        isDialogOpen={isOpen}
        onCancel={() => dispatch(setIsOpenCreateUserFormDialog(false))}
        onCreate={handleCreateNewUser}
      />
    </>
  );
};

export default AssignContributorDialog;
