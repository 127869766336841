import { makeRequest } from '@/api/axios/client';

const createUser = async ({
  organisation,
  email,
  name,
  role,
}: {
  organisation: string;
  email: string;
  name: string;
  role?: string[];
}) => {
  const { data, error } = await makeRequest({
    method: 'POST',
    url: `${process.env.NEXT_PUBLIC_REST_API_URL}/users`,
    data: {
      organisation_id: organisation,
      email,
      name,
      userRole: role,
    },
  });

  return {
    data: error ? null : { id: data!.id },
    error,
  };
};

export default createUser;
