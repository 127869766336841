import { makeRequest } from '@/api/axios/client';
import { ContributorFunction } from '@/store/types/admin';

const assignContributorToIncident = async ({
  incidentId,
  userId,
  roles,
}: {
  incidentId: string;
  roles: ContributorFunction[];
  userId: string;
}): Promise<{
  error: { message: string } | null;
  data?: { success: boolean } | null;
}> => {
  const { error } = await makeRequest({
    method: 'POST',
    url: `${process.env.NEXT_PUBLIC_REST_API_URL}/incidents/${incidentId}/assignments`,
    data: {
      userId,
      roles: roles,
    },
  });

  return {
    data: error ? null : { success: true },
    error,
  };
};

export default assignContributorToIncident;
