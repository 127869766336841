import { FeatureFlag } from './types';
import { getClient } from '@/api/axios/client';

export const getFlags = async (endpoint: string) => {
  try {
    const { data } = await getClient({
      disableAuthInterceptor: true,
    })(endpoint);
    return Promise.resolve({ flags: data ?? [] });
  } catch (error) {
    return Promise.resolve({ error });
  }
};

export const createFlag = async (flag: FeatureFlag, endpoint: string) => {
  try {
    await getClient({
      disableAuthInterceptor: true,
    }).post(endpoint, flag);
    return Promise.resolve({ success: true });
  } catch (error) {
    return Promise.resolve({ error });
  }
};

export const deleteFlag = async (name: string, endpoint: string) => {
  try {
    await getClient({
      disableAuthInterceptor: true,
    }).delete(`${endpoint}/${name}`);

    return Promise.resolve({ success: true });
  } catch (error) {
    return Promise.resolve({ error });
  }
};

export const updateFlagStatus = async (
  name: string,
  status: boolean,
  endpoint: string
) => {
  try {
    await getClient({
      disableAuthInterceptor: true,
    }).post(`${endpoint}/${name}`, { active: status });

    return Promise.resolve({ success: true });
  } catch (error) {
    return Promise.resolve({ error });
  }
};
