import { locales } from '@/components/Question/questionControls/DatePicker/locales';
import {
  isDevelopment,
  isIntegration,
  isProduction,
  isStaging,
} from '@/constants/env';
import { Advice } from '@/store/types/advice';
import { EntityType } from '@/store/types/basicTypes';
import { Module } from '@/store/types/module';
import {
  ComponentReferenceObject,
  ControlTypes,
  Question,
  QuestionAnswerValue,
} from '@/store/types/question';
import { Tip } from '@/store/types/tip';
import { isValid } from 'date-fns';
import { format, formatInTimeZone } from 'date-fns-tz';
import { sanitize } from 'isomorphic-dompurify';
import { v4 as uuid } from 'uuid';

export const getIsClosure = (questionnaireName: string) => {
  return questionnaireName === 'cyber-ir-closure';
};

export const getIsIntake = (questionnaireName: string) => {
  return questionnaireName === 'cyber-ir-intake';
};

export const getUserFriendlyQuestionnaireName = (questionnaireName: string) => {
  switch (questionnaireName) {
    case 'cyber-ir-closure':
      return 'Closure';
    case 'cyber-ir-intake':
      return 'Intake';
    default:
      return questionnaireName;
  }
};

export const getSandboxCaseId = (questionnaireType: 'intake' | 'closure') => {
  const { env } = getEnvironmentData();
  const envType = env as 'integration' | 'staging' | 'production';
  const data = {
    integration: {
      intake: process.env.NEXT_PUBLIC_INTEGRATION_EDITOR_INTAKE_SANDBOX_ID,
      closure: process.env.NEXT_PUBLIC_INTEGRATION_EDITOR_CLOSURE_SANDBOX_ID,
    },
    staging: {
      intake: process.env.NEXT_PUBLIC_STAGING_EDITOR_INTAKE_SANDBOX_ID,
      closure: process.env.NEXT_PUBLIC_STAGING_EDITOR_CLOSURE_SANDBOX_ID,
    },
    production: {
      intake: process.env.NEXT_PUBLIC_PRODUCTION_EDITOR_INTAKE_SANDBOX_ID,
      closure: process.env.NEXT_PUBLIC_PRODUCTION_EDITOR_CLOSURE_SANDBOX_ID,
    },
  };
  return data[envType][questionnaireType];
};

export const getIsQuestion = (entity: ComponentReferenceObject): boolean =>
  entity.componentType === 'QUESTION';
export const getIsTip = (entity: ComponentReferenceObject): boolean =>
  entity.componentType === 'TIP';
export const convertNewLinesToBreakTags = (text = ''): string =>
  text.replaceAll('\n', '<br />');
export const sanitizeHtml = (html: string): string =>
  sanitize(html, {
    FORBID_TAGS: ['button'],
  });
export const removeParagraphs = (str: string) => {
  return sanitize(str, { FORBID_TAGS: ['p'] });
};

export const removeAllTags = (str: string) => {
  const sanitized = sanitize(str, { ALLOWED_TAGS: ['br'] });
  return sanitized.replace(/<p>|<\/p>|<br>/g, ' ');
};
export const getEntityText = (
  entity: Question | Module | Tip | Advice | undefined
): string => {
  if (!entity) return '';

  return entity.entityType === EntityType.Tip ||
    entity.entityType === EntityType.Advice
    ? entity.text
    : entity.label;
};
export const getEntityTypeAsText = (entityType: EntityType): string => {
  switch (entityType) {
    case EntityType.Module:
      return 'Module';
    case EntityType.Question:
      return 'Question';
    case EntityType.Tip:
      return 'Tip';
    case EntityType.Advice:
      return 'Advice';
    case EntityType.CaseType:
      return 'Case Type';
    case EntityType.ModuleGroup:
      return 'Module group';
    case EntityType.Indicator:
      return 'Indicator';
  }
};
export const pluraliseString = (str: string): string => {
  if (!str) return '';
  const lastChar = str.charAt(str.length - 1);
  if (lastChar === 's') {
    return `${str}es`;
  }
  if (str.toLowerCase() === 'advice') {
    return str;
  }
  return `${str}s`;
};

export const getIsMultipleChoiceQuestion = (control: ControlTypes) => {
  return !!(control === ControlTypes.MultiSelect);
};

export const getIsSearchableMultiChoiceQuestion = (control: ControlTypes) => {
  return !!(control === ControlTypes.MultiSelectSearch);
};

export const getIsMultipleChoiceValue = (value: QuestionAnswerValue) => {
  return Array.isArray(value);
};

export const getQuestionHasOptions = (control: ControlTypes) => {
  return !!(
    control === ControlTypes.MultiSelectSearch ||
    control === ControlTypes.MultiSelect ||
    control === ControlTypes.Radio ||
    control === ControlTypes.Select
  );
};

export const getuuid = () => uuid();

export const getformattedTimestamp = (timestamp: string): string => {
  const date = new Date(timestamp);

  const day = date.toLocaleDateString('en-GB', { day: '2-digit' });
  const month = date.toLocaleDateString('en-GB', { month: 'short' });
  const year = date.toLocaleDateString('en-GB', { year: 'numeric' });

  const hours = date.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
  });

  return `${day} ${month} ${year}, ${hours}`;
};

export const getFormattedDate = (isoDateString: string) => {
  let date = new Date(isoDateString || '');

  let month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are 0 based, hence +1
  let day = ('0' + date.getDate()).slice(-2);
  let year = date.getFullYear();

  return `${month}/${day}/${year}`;
};

export const truncateString = (str: string, maxLength: number) => {
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + '...';
  }
  return str;
};

export const getPossessiveName = (name: string) => {
  if (!name) {
    return '';
  }
  if (name?.endsWith?.('s')) {
    return `${name}'`;
  } else {
    return `${name}'s`;
  }
};

export const isNumberString = (val: string) => !isNaN(Number(val));

export const removeDuplicateEntities = (
  components: ComponentReferenceObject[]
) => {
  return components
    .slice()
    .reverse()
    .filter(
      (item, index, self) => index === self.findIndex((t) => t.id === item.id)
    )
    .reverse();
};
export const isEmptyTags = (str = '') => {
  return !sanitize(str, { ALLOWED_TAGS: [] }).length;
};

export const getEnvironmentData = () => {
  if (isDevelopment) {
    return {
      name: 'Integration',
      env: 'integration',
      analyticsSiteId: '13',
    };
  }
  if (isIntegration) {
    return {
      name: 'Integration',
      env: 'integration',
      analyticsSiteId: '13',
    };
  }
  if (isStaging) {
    return {
      name: 'Staging',
      env: 'staging',
      analyticsSiteId: '14',
    };
  }
  if (isProduction) {
    return {
      name: 'Production',
      env: 'production',
      analyticsSiteId: '15',
    };
  }
  return {
    name: 'Integration',
    env: 'integration',
    analyticsSiteId: '13',
  };
};

export const isNullishTimestamp = (timestamp: string) => {
  if (!timestamp) return true;
  const nullishDate = new Date('0001-01-01T00:00:00Z');
  const parsedDate = new Date(timestamp);
  return parsedDate.getTime() === nullishDate.getTime();
};

export const formatDateTime = (date: string | null) => {
  if (!date || isNaN(new Date(date).getTime())) return '';

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const userLocale = navigator.languages[0] || navigator.language;

  const locale =
    locales[userLocale?.toLowerCase() as keyof typeof locales] ||
    locales['en-gb'];

  if (isValid(new Date(date))) {
    const localTime = formatInTimeZone(date, userTimeZone, 'hh:mm a');
    const formattedDate = format(new Date(date), 'PP', { locale });
    return `${formattedDate}, ${localTime}`;
  }

  return '';
};

export const removeSearchParamsFromUrl = () => {
  const url = new URL(window.location.href);
  url.search = '';
  history.replaceState(null, '', url.toString());
};

export const isOnLoginRoute = () =>
  typeof window !== 'undefined'
    ? window.location.href.includes('/login')
    : false;

export const isOnUnAuthorizedRoute = () =>
  typeof window !== 'undefined'
    ? window.location.href.includes('/unauthorized')
    : false;

export const isOnLogoutRoute = () =>
  typeof window !== 'undefined'
    ? window.location.href.includes('/logout')
    : false;
