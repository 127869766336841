import { Alert, AlertTitle, Box, TableContainer } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectIsLoadingContributors,
  selectContributorsEntitiesAsArray,
  updateContributorFilters,
  selectContributorsFilters,
  resetContributorsFilters,
} from '@/store/slices/admin/admin';
import { Contributor } from '@/store/types/admin';
import { Button, SnackbarTypes, Table as TableMUI } from '@s-rm/react-ui-lib';
import { useEffect, useState } from 'react';
import { setIsAddContributorModalOpen } from '@/store/slices/ui';
import {
  getContributorsTableHeader,
  getContributorsTableRows,
} from './utils/getContributorsTableData';
import fetchContributorsThunk from '@/store/thunks/admin/fetchContributorsThunk';
import { useRouter } from 'next/router';
import { AddContributorDialog } from '../AddContributorDialog/AddContributorDialog';
import EditContributorDialog from '../EditContributorDialog/EditContributorDialog';
import ConfirmationModal from '@/components/ConfirmationModal';
import removeContributorFromIncident from '@/api/admin/contributors/removeContributorFromIncident';
import updateSnackbarNotification from '@/store/thunks/updateSnackbar';

const ContributorsTable = () => {
  const dispatch = useAppDispatch();
  const contributors = useAppSelector(selectContributorsEntitiesAsArray) || [];
  const isLoading = useAppSelector(selectIsLoadingContributors);
  const router = useRouter();
  const incidentId = router.query.id as string;
  const { pagination } = useAppSelector(selectContributorsFilters);
  const [contributorBeingModified, setContributorBeingModified] = useState('');
  const [contributorBeingRemoved, setContributorBeingRemoved] =
    useState<Contributor | null>(null);

  const fetchContributors = () =>
    dispatch(fetchContributorsThunk({ incidentId }));

  const handleEditContributor = () => {
    setContributorBeingModified('');
    fetchContributors();
  };

  const handleRemoveContributor = async () => {
    const { error } = await removeContributorFromIncident({
      incidentId,
      userId: contributorBeingRemoved?.id || '',
    });

    if (error) {
      dispatch(
        updateSnackbarNotification({
          message: 'Failed to remove contributor',
          type: SnackbarTypes.Error,
        })
      );
    }

    setContributorBeingRemoved(null);
    fetchContributors();
  };

  useEffect(() => {
    dispatch(
      updateContributorFilters({
        filters: [],
      })
    );
    fetchContributors();
    return () => {
      dispatch(resetContributorsFilters());
    };
  }, [dispatch, incidentId]);

  return (
    <>
      <AddContributorDialog onAddContributor={fetchContributors} />
      <EditContributorDialog
        contributorId={contributorBeingModified}
        onCancel={() => setContributorBeingModified('')}
        onSave={handleEditContributor}
      />
      {contributorBeingRemoved && (
        <ConfirmationModal
          onClose={() => setContributorBeingRemoved(null)}
          isOpen={!!contributorBeingRemoved.id}
          title='Remove contributor'
          onConfirm={handleRemoveContributor}
        >
          <Alert severity='warning'>
            <AlertTitle>
              Are you sure you want to remove{' '}
              <i>{contributorBeingRemoved.name}</i>?
              <br />
              They will no longer have access to the incident.
            </AlertTitle>
          </Alert>
        </ConfirmationModal>
      )}
      <TableContainer>
        <TableMUI
          componentProps={{
            tableProps: {
              //@ts-ignore
              'data-testid': 'contributors-table',
            },
          }}
          stickyHeader={true}
          isLoading={isLoading}
          extendedHeader={
            <Box display='flex' justifyContent='flex-end'>
              <Button
                variant='contained'
                color='primary'
                onClick={() => dispatch(setIsAddContributorModalOpen(true))}
              >
                Add contributor
              </Button>
            </Box>
          }
          header={getContributorsTableHeader()}
          rows={getContributorsTableRows({
            contributors,
            onEdit: (contributorId: string) => {
              setContributorBeingModified(contributorId);
            },
            onRemove: (contributorId: string) => {
              setContributorBeingRemoved(
                contributors.find((c) => c.id === contributorId) || null
              );
            },
          })}
          pagination={{
            paginationData: {
              total: pagination.total,
              offset: pagination.offset / pagination.limit + 1,
              limit: pagination.limit,
            },
            onChange: (newPagination) => {
              dispatch(
                updateContributorFilters({
                  pagination: {
                    offset: newPagination.offset ?? pagination.offset,
                    limit: newPagination.limit ?? pagination.limit,
                  },
                })
              );
              dispatch(fetchContributorsThunk({ incidentId }));
            },
          }}
        />
      </TableContainer>
    </>
  );
};

export default ContributorsTable;
