import { useAppSelector } from '@/hooks';
import {
  selectModuleGroupEntities,
  selectModuleGroups,
} from '@/store/slices/modules';
import {
  selectAllQuestionEntites,
  selectQuestionDisplayOrder,
  selectQuestionValues,
  selectQuestionsVisibility,
} from '@/store/slices/questions';
import { getModuleGroupsByModuleIds } from '@/store/thunks/editor/utils/helpers';
import { ModuleId } from '@/store/types/basicTypes';
import { getIsQuestion } from '@/utils/helpers';

const useRequiredQuestionsCounter = (moduleIds: ModuleId[]) => {
  const questionEntities = useAppSelector(selectAllQuestionEntites);
  const questionsVisibility = useAppSelector(selectQuestionsVisibility);
  const questionValues = useAppSelector(selectQuestionValues);
  const displayOrder = useAppSelector(selectQuestionDisplayOrder);
  const moduleGroups = useAppSelector(selectModuleGroupEntities);

  const modularCounters = moduleIds.reduce(
    (acc, moduleId) => {
      const moduleExistsInGroup = !!getModuleGroupsByModuleIds({
        moduleGroups,
        moduleIds: [moduleId],
      })?.length;
      if (!displayOrder[moduleId] || !moduleExistsInGroup) {
        return acc;
      }
      const allQuestionsInModule = displayOrder[moduleId].filter(
        (componentRef) => getIsQuestion(componentRef)
      );
      const visibleQuestions = allQuestionsInModule
        .filter((question) => questionsVisibility[question.id])
        .map((q) => questionEntities[q.id]);

      const requiredQuestions = visibleQuestions.filter(
        (q) => q.validation?.isRequired
      );

      const requiredQuestionsAnswered = requiredQuestions.filter(
        (q) => !!questionValues[q.id]?.length
      );

      const isComplete =
        requiredQuestionsAnswered.length === requiredQuestions.length;

      return {
        ...acc,
        [moduleId]: {
          answered: requiredQuestionsAnswered.length,
          total: requiredQuestions.length,
          isComplete,
        },
      };
    },
    {} as Record<
      ModuleId,
      {
        answered: number;
        total: number;
        isComplete: boolean;
      }
    >
  );

  const combinedTotal = Object.values(modularCounters).reduce(
    (acc, counter) => acc + counter.total,
    0
  );
  const combinedAnswered = Object.values(modularCounters).reduce(
    (acc, counter) => acc + counter.answered,
    0
  );
  const isComplete = combinedTotal === combinedAnswered;

  return {
    modularCounters,
    combinedCounters: {
      total: combinedTotal,
      answered: combinedAnswered,
      isComplete,
    },
  };
};

export default useRequiredQuestionsCounter;
