import React from 'react';
import TruncatedTextField from '@/components/TruncatedTextField/TruncatedTextField';
import { Row, Chip, Tooltip, Link, ButtonMenu } from '@s-rm/react-ui-lib';
import {
  Contributor,
  ContributorActions,
  ContributorStatus,
} from '@/store/types/admin';
import { ChipVariant } from '@s-rm/react-ui-lib/lib/components/Chip/types';
import { contributorStatusData, contributorRolesData } from '@/data/admin';
import { Box, IconButton, Typography } from '@mui/material';
import {
  getContributorStatusChipVariant,
  getPercentageChipData,
} from '../../utils/helpers';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export const getContributorsTableHeader = () => ({
  cells: [
    {
      id: 'email',
      content: 'Email',
      cellProps: { width: '20%' },
    },
    {
      id: 'name',
      content: 'Name',
      cellProps: { width: '15%' },
    },
    {
      id: 'status',
      content: 'Status',
      cellProps: { width: '10%' },
    },
    {
      id: 'role',
      content: 'Role',
      cellProps: { width: '20%' },
    },
    {
      id: 'completionRate',
      content: 'Completion rate',
      cellProps: { width: '10%' },
    },
    {
      id: 'actions',
      content: 'Actions',
      cellProps: { sx: { textAlign: 'right', paddingRight: '40px' } },
    },
  ],
});

export const getContributorsTableRows = ({
  contributors,
  onEdit,
  onRemove,
}: {
  contributors: Contributor[];
  onEdit: (id: string) => void;
  onRemove: (id: string) => void;
}): Row[] => {
  return contributors.filter(Boolean).map((contributor) => {
    const completionRateData = getPercentageChipData(
      contributor.completionRate as number
    );
    return {
      id: contributor.id,
      cells: [
        {
          id: 'email',
          content: (
            <Tooltip
              title={contributor.email}
              placement='bottom-start'
              displayOnlyOnTruncation
            >
              <TruncatedTextField
                value={contributor.email ?? '-'}
                truncate={true}
                noWrap={true}
              />
            </Tooltip>
          ),
        },
        {
          id: 'name',
          content: (
            <Tooltip
              title={contributor.name}
              placement='bottom-start'
              displayOnlyOnTruncation
            >
              <TruncatedTextField
                value={contributor.name ?? '-'}
                truncate={true}
                noWrap={true}
              />
            </Tooltip>
          ),
        },
        {
          id: 'status',
          content: (
            <Chip
              colorVariant={getContributorStatusChipVariant(contributor.status)}
              label={
                contributorStatusData[contributor.status]?.label ??
                contributorStatusData[ContributorStatus.Pending].label
              }
              rounded
            />
          ),
        },
        {
          id: 'role',
          content:
            (contributor?.roles ?? []).length > 0 ? (
              <Tooltip
                title={
                  contributor?.roles && contributor.roles.length > 0
                    ? contributor.roles
                        .map((role) => contributorRolesData[role]?.label || '-')
                        .join(', ')
                    : '-'
                }
                placement='bottom-start'
                displayOnlyOnTruncation
              >
                <Chip
                  label={
                    contributor?.roles && contributor.roles.length > 0
                      ? contributor.roles
                          .map(
                            (role) => contributorRolesData[role]?.label || '-'
                          )
                          .join(', ')
                      : '-'
                  }
                  size='small'
                  sx={{
                    fontSize: '11px',
                  }}
                  variant='outlined'
                  rounded={true}
                />
              </Tooltip>
            ) : (
              <Typography variant='body1'>-</Typography>
            ),
        },
        {
          id: 'completionRate',
          content:
            contributor.completionRate === null ? (
              '-'
            ) : (
              <Chip
                colorVariant={completionRateData.colorVariant}
                icon={<completionRateData.icon sx={{ height: '1rem' }} />}
                label={<>{`${contributor.completionRate}%`}</>}
                rounded
              />
            ),
        },
        {
          cellProps: { sx: { textAlign: 'right', paddingRight: '40px' } },
          id: 'actions',
          content: (
            <ButtonMenu
              providedButton={
                <IconButton>
                  <MoreVertIcon />
                </IconButton>
              }
              options={[
                {
                  label: 'Edit',
                  value: ContributorActions.EDIT_CONTRIBUTOR,
                  onClick: () => onEdit(contributor.id),
                },
                {
                  label: 'Remove',
                  value: ContributorActions.DELETE_CONTRIBUTOR,
                  onClick: () => onRemove(contributor.id),
                },
              ]}
              label='Select Action'
            />
          ),
        },
      ],
    };
  });
};
