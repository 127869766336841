import { ModuleId } from '@/store/types/basicTypes';
import { ModuleGroup } from '@/store/types/module';
import { ModuleDisplayStructure } from '../types';

export const getStaticModuleDisplayStructure = ({
  moduleGroups,
  staticModuleIds,
  triggeredModules,
}: {
  moduleGroups: { entities: { [key: ModuleId]: ModuleGroup }; ids: ModuleId[] };
  staticModuleIds: ModuleId[];
  triggeredModules: { moduleId: ModuleId }[];
}) => {
  return moduleGroups.ids.reduce((acc, id: ModuleId) => {
    const group = moduleGroups.entities[id];
    const relatedModules = group.moduleIds.filter(
      (id) =>
        staticModuleIds?.includes(id) ||
        triggeredModules.find((m) => m.moduleId === id)
    );

    if (!relatedModules.length) {
      return acc;
    }

    return [
      ...acc,
      {
        groupId: group.id,
        groupLabel: group.label,
        relatedModules,
      },
    ];
  }, [] as ModuleDisplayStructure[]);
};
