import { Box, Container, Divider, IconButton, Typography } from '@mui/material';
import logo from '../../../assets/img/polus-response.png';
import Image from 'next/image';
import { Button, Chip, ChipVariant } from '@s-rm/react-ui-lib';
import { ProfileMenu } from './ProfileMenu';
import { useAppSelector } from '@/hooks';
import { selectSelectedCaseTypes } from '@/store/slices/caseTypes';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import {
  selectClient,
  selectCurrentQuestionnaire,
  selectIsDiagnosisEnabled,
  selectQuestionnaireCompletedAt,
  selectQuestionnaireLabel,
  selectQuestionnaireStartedAt,
} from '@/store/slices/questionnaire';
import {
  formatDateTime,
  getUserFriendlyQuestionnaireName,
} from '@/utils/helpers';
import { SettingsMenu } from './SettingsMenu';
import { selectIsEditMode } from '@/store/slices/ui';
import NavBar from '@/components/NavBar/NavBar';
import { NetworkMessages } from '../NetworkMessages';
import { OnlineStatusContext } from '@/context/onlineStatusContext';
import { useContext, useEffect, useState } from 'react';
import QuestionnaireSelector from './QuestionnaireSelector';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useRouter } from 'next/router';
import { Incident } from '@/store/types/admin';
import getIncidentDetailsById from '@/api/admin/incidents/getIncidentDetailsById';
import { AccessPermissions } from '@/store/types/config';
import useHasAuthorization from '@/hooks/useHasAuthorization';

const QuestionnaireHeader = () => {
  const selectedCaseTypes = useAppSelector(selectSelectedCaseTypes);
  const { name: clientName = '' } = useAppSelector(selectClient) ?? {};
  const { projectId: incidentId } = useAppSelector(selectCurrentQuestionnaire);
  const startedAt = useAppSelector(selectQuestionnaireStartedAt);
  const completedAt = useAppSelector(selectQuestionnaireCompletedAt);
  const isEditMode = useAppSelector(selectIsEditMode);
  const questionnaireLabel = useAppSelector(selectQuestionnaireLabel);
  const isDiagnosisEnabled = useAppSelector(selectIsDiagnosisEnabled);
  const { isOnline, wasOffline } = useContext(OnlineStatusContext);
  const router = useRouter();
  const [incident, setIncident] = useState<Incident | null>(null);
  const canViewIncident = useHasAuthorization({
    requiredPermissions: [AccessPermissions.ViewIncident],
  });

  const handleBackClick = () => {
    router.push(canViewIncident ? `/incident/${incidentId}` : '/incidents');
  };

  useEffect(() => {
    if (incidentId) {
      (async () => {
        const { data } = await getIncidentDetailsById(incidentId);
        setIncident(data);
      })();
    }
  }, [incidentId]);

  return (
    <Box pt={1} mb={3}>
      <Container maxWidth='xl'>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Box display='flex' alignItems='center' gap={2}>
            <IconButton onClick={handleBackClick}>
              <ArrowBackIosIcon />
            </IconButton>
            <Image src={logo} alt='asm-logo' height={30} />
            <Box bgcolor='grey.400' width='2px' height='1.5rem' />
            <Typography
              data-testid='page-title'
              fontSize='1.5rem'
              mb={0}
              color='grey.700'
              fontWeight={400}
            >
              {getUserFriendlyQuestionnaireName(questionnaireLabel)}
            </Typography>
            {isEditMode && (
              <Typography
                fontStyle='italic'
                fontWeight={300}
                fontSize='1.1rem'
                mb='-1px'
              >
                Editor mode
              </Typography>
            )}
          </Box>

          <Box display='flex' alignItems='center' gap={2}>
            <Chip
              colorVariant={ChipVariant.Primary}
              label={
                <Box>
                  <b>Incident: </b> {incident?.title}
                </Box>
              }
            />
            {!isEditMode && <QuestionnaireSelector />}
            <ProfileMenu />
          </Box>
        </Box>
        <Box
          mt={0.5}
          display='flex'
          alignItems='center'
          justifyContent='space-between'
        >
          <Box mt={2}>{isDiagnosisEnabled && <NavBar />}</Box>
          <Box display='flex' alignItems='center' gap={1}>
            {selectedCaseTypes.map((caseType) => (
              <Chip
                key={caseType.id}
                label={caseType.label}
                colorVariant={ChipVariant.Primary}
              />
            ))}
            {clientName && (
              <Chip
                label={<span>Client: {clientName}</span>}
                icon={<PeopleAltOutlinedIcon />}
                colorVariant={ChipVariant.Transparent}
                sx={{ border: 'none' }}
              />
            )}
            {startedAt && (
              <Chip
                label={`${formatDateTime(startedAt)} - ${formatDateTime(
                  completedAt ?? ''
                )}`}
                sx={{
                  color: 'grey.500',
                }}
              />
            )}
            <SettingsMenu />
          </Box>
        </Box>
      </Container>
      {!isOnline && NetworkMessages.Offline()}
      {wasOffline && isOnline && NetworkMessages.Reconnect()}
    </Box>
  );
};

export default QuestionnaireHeader;
