import { Question } from '@/store/types/question';
import { Tip } from '@/store/types/tip';
import { CaseType, ProccessStep } from '@/store/types/caseType';
import { Indicator } from '@/store/types/indicator';
import { Module, ModuleGroup } from '@/store/types/module';
import { Advice } from '@/store/types/advice';
import formatAnswers from '../utils/formatAnswers';
import {
  FormattedCase,
  FormattedDescription,
  FormattedQuestionnaire,
} from '@/api/types/initialData';
import { convertNewLinesToBreakTags, getuuid } from '@/utils/helpers';
import { EntityType } from '@/store/types/basicTypes';
import { Integration } from '@/store/types/integrations';

export const getFormattedCase = (caseData: any): FormattedCase => ({
  caseId: caseData.projectId || '',
  selectedCaseTypes: caseData.selectedCaseTypeIds || [],
  selectedIndicators: caseData.selectedIndicatorIds || [],
  dismissedIndicators: caseData.dismissedIndicatorIds || [],
  manuallySelectedModules: caseData.selectedModules?.map(
    (m: any) => m.moduleId
  ),
  questionValues: caseData.answers ? formatAnswers(caseData.answers) : {},
  client: {
    name: caseData.client.name || '',
  },
  project: {
    name: caseData.project.name || '',
  },
  isActive: caseData.active, // This field currently is used to control the closure active state.
  isIntakeActive: caseData.isIntakeActive,
  startedAt: caseData.intakeTime,
  completedAt: caseData.closureTime,
  triggeredIntegrations: caseData.triggeredIntegrations || [],
});

export const getFormattedQuestionnaire = (
  questionnaireData: any
): FormattedQuestionnaire => {
  const {
    id: questionnaireId,
    projectId,
    config,
    answers,
    client: { name: clientName = '' } = {},
    project: { name: projectName = '' } = {},
    active: isActive = false,
    startedAt = null,
    completedAt = null,
    description: notes = '', // Note: This field will change questionnaire.description -> questionnaire.notes
    triggeredIntegrations = [],
  } = questionnaireData;
  return {
    questionnaireId,
    projectId,
    selectedCaseTypes: config.features.diagnosis.selectedCaseTypeIds ?? [],
    selectedIndicators: config.features.diagnosis.selectedIndicatorIds ?? [],
    dismissedIndicators: config.features.diagnosis.dismissedIndicatorIds ?? [],
    manuallySelectedModules:
      config.features.addModules.manuallySelectedModules ?? [],
    questionValues: answers ? formatAnswers(answers) : {},
    client: {
      name: clientName,
    },
    project: {
      name: projectName,
    },
    isDiagnosisEnabled: config.features.diagnosis.toggled,
    isAddModulesEnabled: config.features.addModules.toggled,
    isNotesEnabled: config.features.addNotes.toggled,
    staticModuleIds: config.staticModuleIds || [],
    isActive,
    startedAt,
    completedAt,
    triggeredIntegrations,
    notes,
    label: config.label,
  };
};

export const getFormattedDescription = (
  description: any
): FormattedDescription =>
  description
    ? {
        text: description.text,
        hasSuggestedIndicators: description.hasSuggestedIndicators,
        suggestedIndicators:
          description.suggestedIndicators?.indicatorIds || [],
      }
    : {
        text: '',
        hasSuggestedIndicators: false,
        suggestedIndicators: [],
      };

export const getFormattedQuestion = (question: any): Question => ({
  id: question.id,
  entityType: EntityType.Question,
  control: question.control,
  placeholder: question.placeholder,
  guidance: question.guidance,
  label: convertNewLinesToBreakTags(question.label),
  triggers: question.triggers.map((trigger: any) => ({
    componentType: trigger.componentType,
    componentId: trigger.componentId,
    action: trigger.action,
    value: trigger.triggeringValue,
    id: getuuid(),
  })),
  options: question.options.map((option: any) => ({
    label: option.label,
    value: option.value,
    id: getuuid(),
  })),
  validation: {
    pattern: question.validation.pattern,
    isRequired: question.validation.required,
    maxLength: question.validation.maxLength,
    minLength: question.validation.minLength,
    maxValue: question.validation.maxValue,
    minValue: question.validation.minValue,
    longText: question.validation.longText,
  },
  version: question.version,
  isSessionOnly: question.isSessionOnly || false,
  integrationTags: question.integrationTags || [],
});

export const getFormattedTip = (tip: any): Tip => ({
  id: tip.id,
  label: tip.label,
  text: convertNewLinesToBreakTags(tip.text),
  advice: tip.linkedAdviceIds,
  entityType: EntityType.Tip,
  isSessionOnly: tip.isSessionOnly || false,
});

export const getFormattedCaseType = (caseType: any): CaseType => ({
  id: caseType.id,
  label: convertNewLinesToBreakTags(caseType.label),
  indicators: caseType.indicatorIds,
  modules: caseType.moduleIds,
  triggeredModules: [],
  isSessionOnly: caseType.isSessionOnly || false,
  entityType: EntityType.CaseType,
  processStep: caseType.processStep || ProccessStep.Intake,
});

export const getFormattedIndicator = (indicator: any): Indicator => ({
  id: indicator.id,
  label: indicator.label,
  isSessionOnly: indicator.isSessionOnly || false,
});

export const getFormattedModule = (module: any): Module => ({
  id: module.id,
  label: module.label,
  description: convertNewLinesToBreakTags(module.description),
  questions: module.rootQuestionIds,
  entityType: EntityType.Module,
  isSessionOnly: module.isSessionOnly || false,
  roles: module.role || [],
});

export const getFormattedModuleGroup = (group: any): ModuleGroup => ({
  id: group.id,
  label: group.label,
  moduleIds: group.moduleIds,
  priorityIndex: group.priorityIndex,
  isSessionOnly: group.isSessionOnly || false,
});

export const getFormattedAdvice = (advice: any): Advice => ({
  id: advice.id,
  label: advice.label,
  text: convertNewLinesToBreakTags(advice.text),
  entityType: EntityType.Advice,
  isSessionOnly: advice.isSessionOnly || false,
});

export const getFormattedIntegrations = (integrations: any): Integration => ({
  name: integrations?.name,
  id: integrations?.id,
  description: integrations?.description,
});
