import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { clearRecentlyCreatedOrg } from '@/store/slices/admin/admin';

const useSyncMostRecentlyCreatedOrg = (
  recentlyCreatedOrgs: Record<
    string,
    {
      organisationId: string;
      organisationName: string;
    } | null
  >,
  handleChange: (key: string, value: any) => void,
  watchedFields: string[] = [],
  extraDep?: any
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const watchedFieldsSet = new Set(watchedFields);
    Object.entries(recentlyCreatedOrgs)
      .filter(
        ([field, data]) => watchedFieldsSet.has(field) && data?.organisationId
      )
      .forEach(([field, data]) => {
        const optionsObject = {
          value: data!.organisationId,
          label: data!.organisationName,
          secondaryLabel: '',
        };

        handleChange(field, optionsObject);
        dispatch(clearRecentlyCreatedOrg(field));
      });
  }, [recentlyCreatedOrgs, dispatch, handleChange, watchedFields, extraDep]);
};

export default useSyncMostRecentlyCreatedOrg;
