import {
  setIsLoadingUsers,
  selectUsersFilters,
  setUsers,
  updateUsersFilters,
  addUsers,
} from '@/store/slices/admin/admin';
import { AppThunk } from '../../store';
import { createError } from '@/utils/errorHandler';
import { ErrorCodes } from '@/constants/errors';
import getAllUsers from '@/api/admin/user/getAllUsers';

interface FetchUsersThunkParams {
  addToExisting?: boolean;
}

const fetchUsersThunk =
  ({ addToExisting }: FetchUsersThunkParams): AppThunk =>
  async (dispatch, getState) => {
    const {
      pagination: { limit = 10, offset = 0 },
      filters,
      sort,
    } = selectUsersFilters(getState());

    dispatch(setIsLoadingUsers(true));

    const { data, error } = await getAllUsers({
      limit,
      offset: offset || 0,
      filters,
      sortColumn: sort?.column,
      sortDirection: sort?.direction,
    });

    if (error) {
      createError({
        code: ErrorCodes.GetAllUsers,
        displaySnackbar: true,
      });
      dispatch(setIsLoadingUsers(false));
      return;
    }

    addToExisting
      ? dispatch(addUsers(data!.data))
      : dispatch(setUsers(data!.data));

    dispatch(
      updateUsersFilters({
        pagination: {
          offset: offset || 0,
          // @ts-ignore
          limit,
          total: data!.pagination.total,
        },
        ...(filters && { filters }),
      })
    );
    dispatch(setIsLoadingUsers(false));
  };

export default fetchUsersThunk;
