import { getFeatureFlagFromStorage } from '@/components/FeatureFlags/utils/checkAndSetFeatureFlag';
import roleToPermissionMap from '@/data/roleToPermissionsMap.json';
import { AccessPermissions, UserRoleNames } from '@/store/types/config';

export const mockPermissionsForUser = [
  'VIEW_INCIDENT',
  'VIEW_INCIDENTS',
  'VIEW_CONTRIBUTORS',
  'VIEW_ORGANISATIONS',
  'VIEW_QUESTIONNAIRES',
  'VIEW_USER_DETAIL',
  'VIEW_CLOSURE',
  'UPDATE_INSURER_PRIMARY_CONTACT',
  'VIEW_USERS_IN_CURRENT_ORG',
  'VIEW_INCIDENTS_PER_USER',
  'VIEW_INCIDENT_MANAGER_ORGS',
  'UPDATE_INCIDENT_MANAGER_ORG',
  'UPDATE_INCIDENT_MANAGER_PRIMARY_CONTACT',
  'VIEW_INCIDENT_BY_CONTRIBUTOR',
  'CREATE_INCIDENT',
  'VIEW_CLOSED_CLOSURE',
  'VIEW_CLOSED_INCIDENT',
  'VIEW_EDITOR',
  'SUBMIT_CLOSURE',
  'SUBMIT_INTAKE',
  'ACCESS_ORGANISATIONS',
  'ACCESS_USERS',
  'ACCESS_CONTRIBUTORS',
  'DELETE_INCIDENT',
] as AccessPermissions[];

const getPermissionsFromRoles = (roles: string[]): AccessPermissions[] => {
  const disablePermissions = getFeatureFlagFromStorage('DISABLE_PERMISSIONS');

  //@ts-ignore
  const filteredPermissions = roles.reduce((acc, role) => {
    const roleNameValue = UserRoleNames[role as keyof typeof UserRoleNames];
    return Array.from(
      new Set([...acc, ...(roleToPermissionMap?.[roleNameValue] ?? [])])
    );
  }, [] as AccessPermissions[]) as unknown as AccessPermissions[];

  return disablePermissions ? mockPermissionsForUser : filteredPermissions;
};

export default getPermissionsFromRoles;
