import {
  ApolloClient,
  InMemoryCache,
  DefaultOptions,
  ApolloLink,
  split,
  HttpLink,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition, Observable } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { isUsingFixtures } from '@/constants/env';
import { setContext } from '@apollo/client/link/context';
import { createError } from '@/utils/errorHandler';
import { ErrorCodes } from '@/constants/errors';
import QueueLink from './links/QueueLink';

export const queueLink = new QueueLink();

const httpLink = new HttpLink({
  uri: `${process.env.API_URL ?? process.env.NEXT_PUBLIC_API_URL}/query`,
  credentials: 'include',
});

// Not yet implemented
const wsLink =
  typeof window !== 'undefined'
    ? new GraphQLWsLink(
        createClient({
          url: 'ws://localhost:4000/graphql',
        })
      )
    : null;

// Handle token attatchment & refreshing between requests.
const authLink = () => {
  return setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        // 'Originating-Application': 'polus-response',
        ...(process.env.NEXT_PUBLIC_CUSTOM_AUTH_TOKEN
          ? {
              'X-Custom-Authorization': `${process.env.NEXT_PUBLIC_CUSTOM_AUTH_TOKEN}`,
            }
          : {}),
      },
    };
  });
};

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink =
  typeof window !== 'undefined' && wsLink != null
    ? split(
        ({ query }) => {
          const def = getMainDefinition(query);
          return (
            def.kind === 'OperationDefinition' &&
            def.operation === 'subscription'
          );
        },
        wsLink,
        httpLink
      )
    : httpLink;

const customHeaderLink = new ApolloLink((operation, forward) => {
  const {
    setContext,
    variables: { caseId },
  } = operation;

  setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'Case-Id': caseId ?? ((caseId as string) || null),
    },
  }));

  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      return Observable.of();
    });
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    if ('statusCode' in networkError && networkError.statusCode === 403) {
      createError({
        code: ErrorCodes.InvalidApiToken,
        displaySnackbar: true,
        displayRefreshDialog: true,
      });
      return Observable.of();
    } else {
      createError({ code: ErrorCodes.NetworkError, displaySnackbar: true });
      return Observable.of();
    }
  }
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const apolloClient = (token?: string) =>
  new ApolloClient({
    link: from([
      //@ts-ignore
      authLink(token),
      errorLink,
      queueLink as unknown as ApolloLink,
      customHeaderLink,
      splitLink,
    ]),
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
  });

const mockClient = {
  query: () => ({ data: {} }),
  mutate: () => ({ data: {} }),
} as unknown as ApolloClient<any>;

const client = isUsingFixtures ? mockClient : apolloClient();

// Used for server-side data fetching.
// Browser client cookies are not available on the server, so we initialise the
// api client with the token from the server request headers.
export const clientWithToken = (token: string) => apolloClient(token);

export default client;
