import DialogContent from '@mui/material/DialogContent';
import { Button, LoadingButton } from '@s-rm/react-ui-lib';

import {
  DialogTitle,
  Typography,
  Dialog,
  DialogActions,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  deleteSession,
  selectSessionInEdit,
  setIsConfrimUseEditorModalOpen,
  setIsSessionModalOpen,
} from '@/store/slices/editor';
import { DialogueTitleContainer } from '../styled';
import ModalContent from './ModalContent';
import { useState } from 'react';
import { useRouter } from 'next/router';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ConfirmDeleteSessionModal from '../ConfirmDeleteSessionModal';
import closeSession from '@/api/mutations/editor/closeSession';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined';
import createSession from '@/api/mutations/editor/createSession';
import mergeSession from '@/api/mutations/editor/mergeSession';
import ConfirmMergeSessionModal from '../ConfirmMergeSessionModal';
import { getRootPathFromRouter } from '@/utils/router';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';

const SessionModal = ({ isOpen }: { isOpen: boolean }) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const handleClose = () => {
    dispatch(setIsSessionModalOpen(false));
  };
  const activeSession = useAppSelector(selectSessionInEdit);
  const [selectedSession, setSelectedSession] = useState<string | null>(null);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showPublishWarning, setShowPublishWarning] = useState(false);
  const routePath = getRootPathFromRouter(router);

  // caseId is still used for intake, id is used for any static "questionnaire".
  // This should hopefully get refactored to just use id in the future.
  const caseId = router.query.caseId || router.query.id;

  const handleClickOpenDraft = () => {
    setIsEditing(true);
    window.location.href = `/${routePath}/${caseId}/edit/${selectedSession}`;
  };

  const handleCreateSession = async () => {
    try {
      setIsLoading(true);
      const {
        data: { id },
      } = await createSession();
      if (id) {
        window.location.href = `/${routePath}/${caseId}/edit/${id}`;
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const handleClickDeleteDraft = async () => {
    try {
      setIsDeleting(true);
      await closeSession(selectedSession as string);
      dispatch(deleteSession(selectedSession as string));
      if (activeSession?.id === selectedSession) {
        window.location.href = `/${routePath}/${caseId}/edit`;
      } else {
        setShowDeleteWarning(false);
        setIsDeleting(false);
      }
    } catch (e) {
      setIsDeleting(false);
    }
  };

  const handlePublishChanges = async () => {
    try {
      setIsPublishing(true);
      const { errors } = await mergeSession(selectedSession as string);
      if (errors) {
        throw new Error(errors[0].message);
      }
      const {
        query: { caseId },
      } = router;
      window.location.href = `/${routePath}/${caseId}/edit`;
    } catch (e) {
      setIsPublishing(false);
    }
  };

  const handleQuitEditor = () => {
    dispatch(setIsConfrimUseEditorModalOpen(true));
  };

  return (
    <Dialog open={isOpen} maxWidth='md' fullWidth>
      <DialogTitle sx={{ textAlign: 'right' }}>
        <DialogueTitleContainer>
          <Typography variant='h6' textTransform='uppercase' fontWeight={700}>
            Draft versions
          </Typography>
          {!activeSession?.id && (
            <Button
              onClick={handleQuitEditor}
              endIcon={<ExitToAppOutlinedIcon />}
              sx={{ background: 'white', marginLeft: '20px' }}
              data-testid='btn-quit-editor'
            >
              Quit Editor
            </Button>
          )}
          {activeSession?.id && (
            <Button onClick={handleClose}>
              <CloseIcon />
            </Button>
          )}
        </DialogueTitleContainer>
      </DialogTitle>
      <DialogContent>
        <ModalContent
          selectedSession={selectedSession}
          onSelectSession={(id: string | null) => setSelectedSession(id)}
          isLoading={isLoading}
        />
        <ConfirmDeleteSessionModal
          onClose={() => setShowDeleteWarning(false)}
          onConfirm={handleClickDeleteDraft}
          isOpen={showDeleteWarning}
          isDeleting={isDeleting}
        />
        <ConfirmMergeSessionModal
          onClose={() => setShowPublishWarning(false)}
          onConfirm={handlePublishChanges}
          isOpen={showPublishWarning}
          isPublishing={isPublishing}
        />
      </DialogContent>
      <DialogActions>
        <Box display='flex' gap={2} py={2} px={3} width='100%'>
          <Button
            disabled={!selectedSession}
            variant='contained'
            color='error'
            onClick={() => setShowDeleteWarning(true)}
            endIcon={<DeleteOutlinedIcon />}
            data-testid='btn-delete-draft'
          >
            Delete
          </Button>
          <LoadingButton
            disabled={!selectedSession}
            variant='contained'
            onClick={handleClickOpenDraft}
            sx={{ marginLeft: 'auto' }}
            endIcon={<ModeOutlinedIcon />}
            loading={isEditing}
            data-testid='btn-edit-draft'
          >
            Edit draft
          </LoadingButton>
          <Button
            disabled={!selectedSession}
            variant='contained'
            onClick={() => setShowPublishWarning(true)}
            endIcon={<PublishOutlinedIcon />}
            data-testid='btn-publish-draft'
          >
            Publish draft
          </Button>
          <LoadingButton
            endIcon={<AddOutlinedIcon />}
            variant={isLoading ? 'contained' : 'outlined'}
            onClick={handleCreateSession}
            loading={isLoading}
            data-testid='btn-create-new-draft-session'
          >
            Create new
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default SessionModal;
