import { makeRequest } from '@/api/axios/client';

const removeContributorFromIncident = async ({
  incidentId,
  userId,
}: {
  incidentId: string;
  userId: string;
}) => {
  const { error } = await makeRequest({
    method: 'DELETE',
    url: `${process.env.NEXT_PUBLIC_REST_API_URL}/incidents/${incidentId}/assignments`,
    data: {
      userId,
    },
  });

  return {
    data: error ? null : { success: true },
    error,
  };
};

export default removeContributorFromIncident;
