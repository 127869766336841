import { useState } from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { useAppSelector } from '@/hooks';
import { selectCurrentUser } from '@/store/slices/config';
import { Avatar } from '@s-rm/react-ui-lib';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LogoutIcon from '@mui/icons-material/Logout';
import { Skeleton } from '@mui/material';
import { AvatarInnerProfile, InfoText, MenuStyled } from '../styled';
import { truncateString } from '@/utils/helpers';

import MenuItem from '@mui/material/MenuItem';
import { ClearQuestionnaireDialog } from '@/components/ClearQuestionnaireDialog/ClearQuestionnaireDialog';
import IntegrationExistsDialog from '@/components/IntegrationExistsDialog/IntegrationExistsDialog';
import { User } from '@/store/types/admin';
import handleLogout from '@/api/auth/logout';

export const ProfileMenu = ({
  userData,
}: {
  showSettings?: boolean;
  userData?: User;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const storeUser = useAppSelector(selectCurrentUser);
  const user = userData || storeUser;

  const initials = user?.name
    ? user.name
        .split(' ')
        .map((n) => n[0])
        .join('')
    : '';

  return (
    <>
      <IconButton
        onClick={handleClick}
        size='small'
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        data-testid='profile-menu-button'
      >
        <Avatar
          sx={{
            width: 38,
            height: 38,
            fontSize: '1rem',
            background: 'grey.300',
            border: '1px solid',
            borderColor: 'grey.300',
          }}
        >
          {!initials ? (
            <Skeleton variant='circular' width={38} height={38} />
          ) : (
            initials
          )}
        </Avatar>
      </IconButton>
      {user && (
        <MenuStyled
          anchorEl={anchorEl}
          id='account-menu'
          open={open}
          onClose={handleClose}
          slotProps={{
            paper: {
              elevation: 0,
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <AvatarInnerProfile data-testid='inner-profile-avatar'>
            <Avatar>{initials || ''}</Avatar>
            <span style={{ paddingLeft: '8px' }}>
              <b>{user.name || 'N/A'}</b>
            </span>
          </AvatarInnerProfile>

          {user?.email && (
            <>
              <InfoText>
                <b>Information</b>
              </InfoText>
              <Divider />
              <MenuItem
                onClick={(e) => e.stopPropagation()}
                disableRipple={true}
                sx={{ cursor: 'text', userSelect: 'text', mt: 1 }}
              >
                <ListItemIcon>
                  <AlternateEmailIcon fontSize='small' htmlColor='#FE6001' />
                </ListItemIcon>
                {truncateString(user.email, 40)}
              </MenuItem>

              <Divider />
              <MenuItem onClick={handleLogout} disableRipple={true}>
                <ListItemIcon>
                  <LogoutIcon fontSize='small' htmlColor='#FE6001' />
                </ListItemIcon>
                Logout
              </MenuItem>
            </>
          )}
        </MenuStyled>
      )}
      <ClearQuestionnaireDialog />
      <IntegrationExistsDialog />
    </>
  );
};
