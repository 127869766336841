import { Incident, IncidentStatus } from '@/store/types/admin';
import { format } from 'date-fns';
import { getIsClosure, getIsIntake } from '@/utils/helpers';

export const getFormattedIncident = (rawIncident: any): Incident => {
  const intake = rawIncident.Questionnaires.find((q: any) =>
    getIsIntake(q.Config.Label)
  );
  const closure = rawIncident.Questionnaires.find((q: any) =>
    getIsClosure(q.Config.Label)
  );
  const totalCompletionRate =
    closure.VisibleMandatoryQuestions > 0
      ? Math.round(
          (closure.AnsweredVisibleMandatoryQuestions /
            closure.VisibleMandatoryQuestions) *
            100
        )
      : 0;
  return {
    id: rawIncident.ID ?? '',
    closedAt: rawIncident.ClosedAt
      ? format(new Date(rawIncident.ClosedAt), 'd MMM yyyy')
      : null!,
    createdAt: rawIncident.CreatedAt
      ? format(new Date(rawIncident.CreatedAt), 'd MMM yyyy')
      : null!,
    status: rawIncident.Status as IncidentStatus,
    victimOrg: rawIncident.Subject.Name ?? '',
    title: rawIncident.Label,
    description: rawIncident.Description,
    insurerName: rawIncident.Insurer.Name,
    insurerPrimaryContactFullName: rawIncident.InsurerPrimaryContact.FullName,
    incidentManagerOrgName: rawIncident.ManagerOrg.Name,
    incidentManagerOrgPrimaryContactFullName:
      rawIncident.ManagerOrgPrimaryContact.FullName,
    insurerId: rawIncident.InsurerId,
    insurerPrimaryContactId: rawIncident.InsurerPrimaryContactId,
    managerOrgPrimaryContactId: rawIncident.ManagerOrgPrimaryContactId,
    managerOrgId: rawIncident.ManagerOrgId,
    roles: rawIncident.roles ?? [],
    intake: {
      caseType: intake?.Config.Features?.Diagnosis?.SelectedCaseTypeIds || [],
      id: intake?.ID ?? '',
      status: intake?.IsActive,
      startDate: intake?.CreatedAt
        ? format(new Date(intake.CreatedAt), 'd MMM yyyy')
        : '-',
      endDate: intake?.ClosedAt
        ? format(new Date(intake.ClosedAt), 'd MMM yyyy')
        : '-',
    },
    closure: {
      id: closure?.ID ?? '',
      status: closure?.IsActive,
      startDate: closure?.CreatedAt
        ? format(new Date(closure.CreatedAt), 'd MMM yyyy')
        : '-',
      endDate: closure?.ClosedAt
        ? format(new Date(closure.ClosedAt), 'd MMM yyyy')
        : '-',
      completionRate: totalCompletionRate,
    },
  };
};

export const formatAndValidateIncident = (incident: Partial<Incident>): any => {
  const formattedIncident = {
    ID: incident.id,
    ClosedAt: incident.closedAt,
    Status: incident.status,
    Label: incident.title,
    Description: incident.description,
    InsurerId: incident.insurerId,
    InsurerPrimaryContactId: incident.insurerPrimaryContactId,
    ManagerOrgId: incident.managerOrgId,
    ManagerOrgPrimaryContactId: incident.managerOrgPrimaryContactId,
  };
  return formattedIncident;
};
