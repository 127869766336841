import { ReactNode } from 'react';
import {
  ThemeProvider as ComponentLibThemeProvider,
  lightTheme,
} from '@s-rm/react-ui-lib';
import { ThemeProvider as MUIThemeProvider, createTheme } from '@mui/material';
import { useRouter } from 'next/router';
import { getIsAdminRoute } from '@/utils/router';

const getTheme = ({ isAdminRoute }: { isAdminRoute: boolean }) => {
  if (isAdminRoute) {
    return createTheme({
      ...lightTheme,
      components: {
        MuiTableCell: {
          styleOverrides: {
            root: {
              fontSize: '13px',
            },
            head: {
              fontSize: '12px !important',
            },
          },
        },
      },
      typography: {
        ...lightTheme.typography,
        h1: {
          fontFamily: 'Soleil, Roboto, Arial, sans-serif',
          fontWeight: 200,
          fontSize: '2.5rem',
        },
        h2: {
          fontFamily: 'Soleil, Roboto, Arial, sans-serif',
          fontWeight: 500,
          fontSize: '1.7rem',
        },
        h3: {
          fontFamily: 'Soleil, Roboto, Arial, sans-serif',
          fontWeight: 300,
          fontSize: '1.3rem',
        },
        h4: {
          fontFamily: 'Soleil, Roboto, Arial, sans-serif',
          fontWeight: 700,
          fontSize: '2.5rem',
        },
        h5: {
          fontFamily: 'Soleil, Roboto, Arial, sans-serif',
          fontWeight: 700,
          fontSize: '2.5rem',
        },
        h6: {
          fontFamily: 'Soleil, Roboto, Arial, sans-serif',
          fontWeight: 700,
          fontSize: '2.5rem',
        },
      },
    });
  }

  return createTheme({
    ...lightTheme,
    palette: {
      ...lightTheme.palette,
    },
  });
};

const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();

  const isAdminRoute = getIsAdminRoute(router);

  const theme = getTheme({ isAdminRoute });

  return (
    <MUIThemeProvider theme={theme}>
      <ComponentLibThemeProvider theme={theme}>
        {children}
      </ComponentLibThemeProvider>
    </MUIThemeProvider>
  );
};

export default ThemeProvider;
