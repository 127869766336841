import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '@/store/initialState';
import { RootState } from '../store';
import { ErrorCodes } from '@/constants/errors';
import { AccessPermissions } from '../types/config';
import { User } from '../types/admin';

export const configSlice = createSlice({
  name: 'config',
  initialState: initialState.config,
  reducers: {
    setHasInitialised: (state, action: PayloadAction<boolean>) => {
      state.hasInitialised = action.payload;
    },
    setIsCaseValid: (state, action: PayloadAction<boolean>) => {
      state.isCaseValid = action.payload;
    },
    addError: (
      state,
      action: PayloadAction<{ code: ErrorCodes; error?: Error }>
    ) => {
      state.errors.push(action.payload);
    },
    setError: (
      state,
      action: PayloadAction<{ code: ErrorCodes; error?: Error }>
    ) => {
      state.errors = [action.payload];
    },
    removeError: (state, action: PayloadAction<ErrorCodes>) => {
      state.errors = state.errors.filter(
        (error) => error.code !== action.payload
      );
    },
    setCurrentUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    setFeatureFlags: (
      state,
      action: PayloadAction<Record<string, boolean>>
    ) => {
      state.featureFlags = action.payload;
    },
    setAccessPermissions: (
      state,
      action: PayloadAction<AccessPermissions[]>
    ) => {
      state.accessPermissions = action.payload;
    },
  },
});

export const selectAccessPermissions = (state: RootState) =>
  state.config.accessPermissions;
export const selectHasInitialised = (state: RootState) =>
  state.config.hasInitialised;
export const selectIsCaseValid = (state: RootState) => state.config.isCaseValid;
export const selectErrors = (state: RootState) => state.config.errors;
export const selectLatestError = (state: RootState) => state.config.errors[0];
export const selectCurrentUser = (state: RootState) => state.config?.user;
export const selectFeatureFlags = (state: RootState) =>
  state.config.featureFlags;
export const {
  setHasInitialised,
  setIsCaseValid,
  addError,
  removeError,
  setCurrentUser,
  setFeatureFlags,
  setError,
  setAccessPermissions,
} = configSlice.actions;

export default configSlice.reducer;
