import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectIsAddContributorModalOpen,
  setIsAddContributorModalOpen,
} from '@/store/slices/ui';
import { ContributorFunction } from '@/store/types/admin';
import { useRouter } from 'next/router';
import assignContributorToIncident from '@/api/admin/contributors/assignContributorToIncident';
import { contributorRolesData } from '@/data/admin';
import AssignContributorDialog from '../AssignContributorDialog/AssignContributorDialog';
import updateSnackbarNotification from '@/store/thunks/updateSnackbar';
import { SnackbarTypes } from '@s-rm/react-ui-lib';

export const AddContributorDialog = ({
  onAddContributor,
}: {
  onAddContributor: () => void;
}) => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectIsAddContributorModalOpen);
  const router = useRouter();
  const incidentId = router.query.id as string;

  const handleOnClickCancel = () => {
    dispatch(setIsAddContributorModalOpen(false));
  };

  const handleOnAssignContributor = async (userInfo: {
    id: string;
    name: string;
    email: string;
    roles: ContributorFunction[];
  }) => {
    dispatch(setIsAddContributorModalOpen(false));
    const { error } = await assignContributorToIncident({
      incidentId,
      userId: userInfo.id,
      roles: userInfo.roles.map(
        (role) =>
          contributorRolesData[role as keyof typeof contributorRolesData]
            .abreviation
      ) as unknown as ContributorFunction[],
    });

    if (error) {
      return dispatch(
        updateSnackbarNotification({
          message: 'An error occurred while assigning the user to the incident',
          type: SnackbarTypes.Error,
        })
      );
    }

    dispatch(
      updateSnackbarNotification({
        message: 'The user has been assigned to the incident!',
        type: SnackbarTypes.Success,
      })
    );

    onAddContributor?.();
  };

  return (
    <AssignContributorDialog
      onAssign={handleOnAssignContributor}
      isDialogOpen={isOpen}
      onCancel={handleOnClickCancel}
    />
  );
};
