import { AppThunk } from '../store';
import {
  setIsConfirmingReopenQuestionnaire,
  setPendingInterceptAction,
} from '../slices/ui';
import updateQuestionValueThunk from './updateQuestionValue';
import { PendingInterceptAction } from '../types/ui';
import updateDescriptionThunk from './updateDescription';
import addSelectedCaseTypesThunk from './addSelectedCaseTypes';
import removeSelectedCaseTypesThunk from './removeSelectedCaseTypes';
import addSelectedModulesThunk from './addSelectedModules';
import {
  addManuallySelectedModules,
  removeManuallySelectedModules,
  removeSelectedModules,
} from '../slices/modules';
import changeIndicatorStateThunk from './changeIndicatorStateThunk';
import { ActionNames } from '../types/actions';
import { setQuestionnaireCompletedAt } from '../slices/questionnaire';
import activateQuestionnaire from './activateQuestionnaire';

const invokePendingInterceptAction =
  (callbackData: { action: string; payload: any }): AppThunk =>
  (dispatch) => {
    switch (callbackData.action) {
      case ActionNames.UpdateQuestionValue:
        dispatch(updateQuestionValueThunk(callbackData.payload));
        break;
      case ActionNames.UpdateDescription:
        dispatch(updateDescriptionThunk(callbackData.payload));
        break;
      case ActionNames.AddSelectedCaseTypes:
        dispatch(addSelectedCaseTypesThunk(callbackData.payload));
        break;
      case ActionNames.RemoveSelectedCaseTypes:
        dispatch(removeSelectedCaseTypesThunk(callbackData.payload));
        break;
      case ActionNames.AddSelectedModules:
        dispatch(addSelectedModulesThunk(callbackData.payload));
        break;
      case ActionNames.AddManuallySelectedModules:
        dispatch(addManuallySelectedModules(callbackData.payload));
        break;
      case ActionNames.RemoveManuallySelectedModules:
        dispatch(removeManuallySelectedModules(callbackData.payload));
        break;
      case ActionNames.RemoveSelectedModules:
        dispatch(removeSelectedModules(callbackData.payload));
        break;
      case ActionNames.ChangeIndicatorState:
        dispatch(changeIndicatorStateThunk(callbackData.payload));
        break;
    }
  };

// Note: For now we maintain two userActionInterceptors the legacy one supports the old intake/closure API
// The beta implementation will replace the above when the questionnaire API is fully functioning and has been tested by QA.
const userActionInterceptor =
  (interceptActions: {
    actions: PendingInterceptAction[];
    shouldContinue?: boolean;
    shouldContinueIntake?: boolean;
  }): AppThunk =>
  (dispatch, getState) => {
    const isQuestionnaireActive = getState().questionnaire.isActive;
    const {
      actions,
      shouldContinue: shouldContinueClosure,
      shouldContinueIntake,
    } = interceptActions;

    if (isQuestionnaireActive) {
      actions.forEach((actionObj) => {
        dispatch(invokePendingInterceptAction(actionObj));
      });
      return;
    }

    // Note: this will become depricated and there would just be one action to re-open the questionnaire.
    if (shouldContinueClosure || shouldContinueIntake) {
      const storedActions = getState().ui.pendingInterceptAction;
      storedActions.forEach((actionObj) => {
        dispatch(invokePendingInterceptAction(actionObj));
      });
      dispatch(setPendingInterceptAction([]));
      dispatch(setQuestionnaireCompletedAt(null));
      dispatch(activateQuestionnaire());
      return;
    }

    dispatch(setPendingInterceptAction(actions));
    dispatch(setIsConfirmingReopenQuestionnaire(true));
  };

export default userActionInterceptor;
