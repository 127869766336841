import client from '@/api/client';
//@ts-ignore
import { UpdateQuestionnaireCompletion } from '@/api/gql/mutations.gql';
import { ErrorCodes } from '@/constants/errors';
import { store } from '@/store';
import { createError } from '@/utils/errorHandler';
import {
  getAnsweredVisibleMandatoryQuestions,
  getVisibleMandatoryQuestions,
} from '../utils/helpers';

const updateQuestionnaireCompletion = async () => {
  const questionnaireId = store.getState()?.questionnaire.questionnaireId;
  const visibleQuestions = store.getState().questions.questionsVisibility;
  const questionValues = store.getState().questions.values;
  const questionEntities = store.getState().questions.entities;

  const visibleMandatoryQuestions = getVisibleMandatoryQuestions(
    visibleQuestions,
    questionEntities
  );

  const { data, errors } = await client.mutate({
    mutation: UpdateQuestionnaireCompletion,
    variables: {
      questionnaireId,
      visibleMandatoryQuestions: visibleMandatoryQuestions.length,
      answeredVisibleMandatoryQuestions: getAnsweredVisibleMandatoryQuestions(
        visibleMandatoryQuestions,
        questionValues
      ),
    },
  });

  if (errors) {
    createError({
      code: ErrorCodes.UpdateQuestionnaireCompletion,
      error: new Error(JSON.stringify(errors)),
      displaySnackbar: true,
    });
  }

  return { data };
};

export default updateQuestionnaireCompletion;
