import { RawOrganisationsApiData } from '@/api/admin/organisations/types';
import { OrganisationRole } from '@/store/types/admin';

export const orgRoleMapping: { [key: string]: string } = {
  INSURER: 'Insurer/Owner',
  'INCIDENT-MANAGER': 'Incident manager',
};

export const reverseRoleMapping: { [key: string]: string } = Object.fromEntries(
  Object.entries(orgRoleMapping).map(([key, value]) => [value, key])
);

export const getFormattedOrganisations = (data: RawOrganisationsApiData[]) => {
  return data.map((org) => {
    return {
      id: org.Id,
      name: org.Name,
      roles: org.Roles ?? [],
    };
  });
};

export const getFormattedRolesForApi = (roles: OrganisationRole[]) => {
  return roles.map(
    (role) => reverseRoleMapping[role] || role
  ) as OrganisationRole[];
};
