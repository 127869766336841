import { createError } from '@/utils/errorHandler';
import { ErrorCodes } from '@/constants/errors';
import updateSnackbarNotification from '../updateSnackbar';
import { SnackbarTypes } from '@s-rm/react-ui-lib';
import createUser from '@/api/admin/user/createUser';
import { AppThunk } from '../../store';
import { setIsCreatingUser } from '@/store/slices/ui';
import { NewUser } from '@/store/types/admin';
import fetchUsersThunk from './fetchUsersThunk';

interface CreateUserThunkParams extends NewUser {
  cb?: ({ id }: { id: string }) => void;
}

const createUserThunk =
  ({ organisation, email, name, role, cb }: CreateUserThunkParams): AppThunk =>
  async (dispatch) => {
    dispatch(setIsCreatingUser(true));
    const { error, data } = await createUser({
      organisation,
      email,
      name,
      role,
    });

    error &&
      createError({
        code: ErrorCodes.CreateUser,
        displaySnackbar: true,
      });

    if (!error) {
      // @ts-ignore
      cb?.({ id: data!.id });
      dispatch(
        updateSnackbarNotification({
          message: 'The user has been successfully created!',
          type: SnackbarTypes.Success,
        })
      );
    }

    dispatch(setIsCreatingUser(false));
    dispatch(fetchUsersThunk({ addToExisting: false }));
  };

export default createUserThunk;
