import { NavigationItem } from '@s-rm/react-ui-lib';

export type NavigationItemWithAccess = NavigationItem & {
  access?: (permissions: {
    canViewUsersTab: boolean;
    canViewOrganisationsTab: boolean;
    canViewIncidentsTab: boolean;
  }) => boolean;
};

export const sidebarNavigationData: NavigationItemWithAccess[] = [
  {
    label: 'Incidents',
    path: '/incidents',
    access: ({ canViewIncidentsTab }) => canViewIncidentsTab,
  },
  {
    label: 'Users',
    path: '/users',
    access: ({ canViewUsersTab }) => canViewUsersTab,
  },
  {
    label: 'Organisations',
    path: '/organisations',
    access: ({ canViewOrganisationsTab }) => canViewOrganisationsTab,
  },
];
