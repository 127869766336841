import { getClient } from '@/api/axios/client';
import getAuthorizedUserTransform, {
  UserWithPermissions,
} from '@/api/transforms/auth/getAuthorizedUserTransform';
import { ErrorCodes } from '@/constants/errors';
import { User } from '@/store/types/admin';
import { isUnauthorizedOrForbidden } from '@/utils/auth';
import { createError } from '@/utils/errorHandler';
import {
  isOnLoginRoute,
  isOnLogoutRoute,
  isOnUnAuthorizedRoute,
  removeSearchParamsFromUrl,
} from '@/utils/helpers';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import { useAppSelector } from './storeHooks';
import { selectHasInitialised } from '@/store/slices/config';

const useAuthCheck = () => {
  const [userData, setUserData] = useState<UserWithPermissions | null>(null);
  const hasInitialised = useAppSelector(selectHasInitialised);

  const params = useSearchParams();
  const code = params.get('idam_code');
  const router = useRouter();
  const shouldAuthenticate =
    router.isReady &&
    !hasInitialised &&
    !isOnLoginRoute() &&
    !isOnUnAuthorizedRoute() &&
    !isOnLogoutRoute();
  const [isLoading, setIsLoading] = useState(true);

  const setUserDataAndLocalStorage = (user: User) => {
    setUserData(getAuthorizedUserTransform(user));
  };

  useEffect(() => {
    const authenticate = async () => {
      try {
        setIsLoading(true);
        if (code) {
          // This should return us a user object -#
          // If we have an idam code (from uri), we pass it to the verify endpoint, and retrieve user data + auth cookie.
          // If we don't have a code, then we should be already authenticated and it should still return user data.
          const { data: verifyData } = await getClient({
            disableAuthInterceptor: true,
            requiresBearer: false,
          }).get(`${process.env.NEXT_PUBLIC_AUTH_URL}/verify/${code}`);

          verifyData.id && removeSearchParamsFromUrl();
        }

        // If we don't have a code, we should already be authenticated and should return user data.
        const { data: userData } = await getClient().get(
          `${process.env.NEXT_PUBLIC_REST_API_URL}/user`
        );

        // Set user details once user is authenticated
        if (userData.id) {
          setUserDataAndLocalStorage(userData);
        }
      } catch (e: any) {
        if (isUnauthorizedOrForbidden(e.response?.status)) {
          if (code) {
            createError({
              displaySnackbar: true,
              code: ErrorCodes.Authentication,
            });
          } else {
            createError({
              displaySnackbar: true,
              code: ErrorCodes.InvalidUserSession,
            });
          }
        } else {
          createError({
            displaySnackbar: true,
            code: ErrorCodes.UnexpectedAuthError,
          });
        }
      } finally {
        setIsLoading(false);
      }
    };
    shouldAuthenticate && authenticate();
  }, [router.isReady, code, router, shouldAuthenticate]);

  return { userData, isLoading };
};

export default useAuthCheck;
