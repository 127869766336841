import { LicenseInfo } from '@mui/x-license';
import React, { useEffect, useMemo } from 'react';
import type { AppProps } from 'next/app';
import '@/styles/globals.css';
import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client';
import client, { queueLink as ql } from '@/api/client';
import ErrorBoundary from '@/components/ErrorBoundary';
import Layout from '@/components/Layout';
import { Provider } from 'react-redux';
import { store } from '@/store';
import { CssBaseline } from '@mui/material';
import StoreInitialiser from '@/components/StoreInitialiser';
import { RawInitialData } from '@/api/types/initialData';
import { ErrorCodes } from '@/constants/errors';
import Snackbar from '@/components/Snackbar/Snackbar';
import { RefreshDialogOverlay } from '@/components/RefreshDialogOverlay';
import type { ReactElement, ReactNode } from 'react';
import type { NextPage } from 'next';
import { useNetworkStatus } from '@/hooks/useNetworkStatus';
import { useBeforeUnloadEvent } from '@/hooks/useBeforeUnloadEvent';
import { OnlineStatusContext } from '@/context/onlineStatusContext';
import { FeatureFlagProvider } from '@/components/FeatureFlags/Provider';
import ThemeProvider from '@/components/ThemProvider/ThemeProvider';
import AuthProvider from '@/components/AuthProvider/AuthProvider';

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUIX_LICENSE_KEY as string);

interface CustomAppProps extends AppProps {
  initialData: RawInitialData;
  caseId: string;
  requiresJWT: boolean;
  error?: ErrorCodes;
  apiKey?: string;
}

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = CustomAppProps & {
  Component: NextPageWithLayout;
};

const CustomApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  const { isOnline, wasOffline } = useNetworkStatus();
  useBeforeUnloadEvent((event) => {
    event.preventDefault();
  });

  useEffect(() => {
    isOnline ? ql.open() : ql.close();
  }, [isOnline]);

  const contextValue = useMemo(
    () => ({ isOnline, wasOffline }),
    [isOnline, wasOffline]
  );

  const getLayout = Component.getLayout ?? ((page) => <Layout>{page}</Layout>);

  return (
    <ApolloProvider client={client as ApolloClient<NormalizedCacheObject>}>
      <Provider store={store}>
        <ThemeProvider>
          <CssBaseline />
          <Snackbar />
          <AuthProvider>
            <ErrorBoundary>
              <FeatureFlagProvider
                endpoint={process.env.NEXT_PUBLIC_FEATURE_FLAGS_URL || ''}
              >
                <StoreInitialiser />
                <RefreshDialogOverlay />
                <OnlineStatusContext.Provider value={contextValue}>
                  {getLayout(<Component {...pageProps} />)}
                </OnlineStatusContext.Provider>
              </FeatureFlagProvider>
            </ErrorBoundary>
          </AuthProvider>
        </ThemeProvider>
      </Provider>
    </ApolloProvider>
  );
};

export default CustomApp;
