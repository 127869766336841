import { NavigationItemWithAccess } from './data';

export const getFilteredNavigation = (
  navigationData: NavigationItemWithAccess[],
  permissions: {
    canViewUsersTab: boolean;
    canViewOrganisationsTab: boolean;
    canViewIncidentsTab: boolean;
  }
): NavigationItemWithAccess[] => {
  return navigationData.filter(
    (item) => !item.access || item.access(permissions)
  );
};
