import DialogContent from '@mui/material/DialogContent';
import { Button } from '@s-rm/react-ui-lib';
import { Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectCaseTypeInEdit,
  selectSingleEntityModifications,
  setActiveEntitySearchList,
  setCaseTypeInEdit,
  toggleResetAndSyncEntityModal,
} from '@/store/slices/editor';
import { DialogueTitleContainer } from '../../styled';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { EntityType } from '@/store/types/basicTypes';
import refreshDisplayThunk from '@/store/thunks/editor/refreshDisplay';
import ModalContent from './ModalContent';
import EditorDialog, {
  EditorDialogTitle,
} from '../../EditorDialog/EditorDialog';
import { selectCaseType } from '@/store/slices/caseTypes';
import ResetButton from '../ResetButton';

const EditCaseTypeModal = ({ isOpen }: { isOpen: boolean }) => {
  const dispatch = useAppDispatch();
  const caseTypeInEdit = useAppSelector(selectCaseTypeInEdit);
  const modifications = useAppSelector((state) =>
    selectSingleEntityModifications(state, 'caseTypes', caseTypeInEdit.id!)
  );
  const isSessionOnly = useAppSelector((state) =>
    selectCaseType(state, caseTypeInEdit.id!)
  )?.isSessionOnly;
  const handleClose = () => {
    dispatch(setCaseTypeInEdit({ id: null, isNew: false }));
    dispatch(refreshDisplayThunk());
  };

  const handleBack = () => {
    dispatch(setCaseTypeInEdit({ id: null, isNew: false }));
    dispatch(setActiveEntitySearchList(EntityType.CaseType));
  };

  const handleReset = () => {
    dispatch(
      toggleResetAndSyncEntityModal({
        isOpen: true,
        entityId: caseTypeInEdit.id!,
        entityType: EntityType.CaseType,
      })
    );
  };

  return (
    <EditorDialog open={isOpen} maxWidth='md' fullWidth>
      <EditorDialogTitle>
        <DialogueTitleContainer>
          <Button
            onClick={handleBack}
            startIcon={<ArrowBackIosNewOutlinedIcon />}
            data-testid='btn-edit-casetype-back'
          >
            Back
          </Button>

          <Typography variant='h6' textTransform='uppercase' fontWeight={700}>
            {`${caseTypeInEdit?.isNew ? 'Add' : 'Edit'}`} CASE TYPE
          </Typography>
          <Box display='flex' gap={2}>
            {!isSessionOnly && !!modifications.length && (
              <ResetButton
                onClick={handleReset}
                testId='btn-reset-sync-ctype'
              />
            )}

            <Button onClick={handleClose} data-testid='btn-close-edit-ctype'>
              <CloseIcon />
            </Button>
          </Box>
        </DialogueTitleContainer>
      </EditorDialogTitle>
      <DialogContent style={{ padding: 0 }}>
        {isOpen && <ModalContent />}
      </DialogContent>
    </EditorDialog>
  );
};

export default EditCaseTypeModal;
