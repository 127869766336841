import getInitialData from '@/api/queries/getInitialData';
import { AppThunk } from '../store';
import getSession from '@/api/queries/editor/getSession';
import { validateInitialData } from '@/utils/auth';
import { createError } from '@/utils/errorHandler';
import { setHasInitialised } from '../slices/config';
import hydrateStoreThunk from './hydrateStore';

const initAppData =
  ({
    questionnaireId: questionnaireIdFromRouter,
    sessionId: sessionIdFromRouter,
  }: {
    questionnaireId?: string;
    sessionId?: string;
  } = {}): AppThunk =>
  async (dispatch, getState) => {
    const sessionId =
      sessionIdFromRouter ?? getState().editor.sessionInEdit?.id;
    let sessionData = null;
    const { data: rawInitialData } = await getInitialData({
      questionnaireId:
        questionnaireIdFromRouter ?? getState().questionnaire.questionnaireId,
    });

    if (sessionId) {
      const { data } = await getSession({ sessionId });
      sessionData = data;
    }

    const errorCode = validateInitialData(rawInitialData);

    // TODO: handle error for when using new api
    if (errorCode) {
      createError({ code: errorCode, displaySnackbar: true });
      return;
    }

    if (rawInitialData) {
      dispatch(hydrateStoreThunk({ rawInitialData, sessionData }));
      dispatch(setHasInitialised(true));
    }
  };

export default initAppData;
