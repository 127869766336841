import client from '@/api/client';
//@ts-ignore
import { AddQuestionnaireAnswer } from '@/api/gql/mutations.gql';
import { ErrorCodes } from '@/constants/errors';
import { store } from '@/store';
import { createError } from '@/utils/errorHandler';

export interface AnswerSaveData {
  questionId: string;
  value: string;
}

const saveAnswer = async ({ questionId, value }: AnswerSaveData) => {
  const questionEntity = store.getState()?.questions.entities[questionId];
  const version = store.getState()?.questions.versions[questionId];
  const questionnaireId = store.getState()?.questionnaire.questionnaireId;

  const { data, errors } = await client.mutate({
    mutation: AddQuestionnaireAnswer,
    variables: {
      questionId,
      value,
      questionType: questionEntity?.control,
      questionVersion: version,
      questionnaireId,
    },
  });

  if (errors) {
    createError({
      code: ErrorCodes.SaveAnswer,
      error: new Error(JSON.stringify(errors)),
      displaySnackbar: true,
    });
  }

  return { data };
};

export default saveAnswer;
