import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { ChipVariant } from '@s-rm/react-ui-lib';
import {
  ContributorStatus,
  IncidentRole,
  IncidentRoleLabelMap,
  IncidentStatus,
  OrganisationRole,
  UserRoleNamesLabelMap,
  UserStatus,
} from '@/store/types/admin';
import { UserRoleNames } from '@/store/types/config';
import { get, isEmpty, every } from 'lodash';

export const getIsValid = (
  formValues: Record<string, any>,
  requiredFields: string[]
) => {
  return requiredFields.every((field) => {
    const value = get(formValues, field);
    if (value === null || value === undefined) {
      return false;
    }

    if (typeof value === 'string') {
      return value.trim().length > 0;
    }

    if (Array.isArray(value)) {
      return !isEmpty(value);
    }

    if (typeof value === 'object') {
      return !isEmpty(Object.values(value).filter(Boolean));
    }

    return !!value;
  });
};

export const EXCLUDED_ROLES = [
  IncidentRole.DFIR,
  IncidentRole.Legal,
  IncidentRole.TAEngagementSpecialist,
  IncidentRole.CLAIMS_TEAM,
];

export const getFilteredIncidentRoleLabels = (
  roles: IncidentRole[]
): string => {
  return roles
    .filter((role) => !EXCLUDED_ROLES.includes(role))
    .map((role) => getIncidentRoleChipLabel(role))
    .filter((label) => label !== '')
    .join(', ');
};

export const validateRequiredFields = (
  formValues: { [key: string]: any },
  requiredFields: string[]
): boolean => {
  return every(requiredFields, (field) => {
    const label = get(formValues, `${field}.label`, '').trim();
    return label !== '';
  });
};

export const getPercentageChipData = (percentage: number) => {
  if (percentage >= 90) {
    return {
      colorVariant: ChipVariant.Success,
      icon: CheckCircleOutlineOutlinedIcon,
    };
  } else if (percentage <= 40) {
    return {
      colorVariant: ChipVariant.Error,
      icon: HourglassEmptyOutlinedIcon,
    };
  } else {
    return {
      colorVariant: ChipVariant.Warning,
      icon: HourglassEmptyOutlinedIcon,
    };
  }
};

export const getContributorStatusChipVariant = (status: ContributorStatus) => {
  switch (status) {
    case ContributorStatus.Active:
      return ChipVariant.Success;
    case ContributorStatus.Suspended:
      return ChipVariant.Error;
    case ContributorStatus.Invited:
      return ChipVariant.Neutral;
    default:
      return ChipVariant.Neutral;
  }
};

export const getIncidentStatusChipVariant = (status: IncidentStatus) => {
  switch (status) {
    case IncidentStatus.Completed:
      return ChipVariant.Success;
    case IncidentStatus.InProgress:
      return ChipVariant.Primary;
    case IncidentStatus.NotStarted:
      return ChipVariant.Warning;
    default:
      return ChipVariant.Warning;
  }
};

export const getQuestionnaireStatusChipVariant = (status: boolean) => {
  switch (status) {
    case true:
      return ChipVariant.Warning;
    case false:
      return ChipVariant.Success;
    default:
      return ChipVariant.Warning;
  }
};

export const getUserStatusChipVariant = (status: UserStatus) => {
  switch (status) {
    case UserStatus.Active:
      return ChipVariant.Success;
    case UserStatus.Pending:
      return ChipVariant.Neutral;
    case UserStatus.Invited:
      return ChipVariant.Transparent;
    case UserStatus.Suspended:
      return ChipVariant.Error;
    default:
      return ChipVariant.Neutral;
  }
};

export const getUserStatusChipLabel = (status: UserStatus) => {
  switch (status) {
    case UserStatus.Active:
      return 'Active';
    case UserStatus.Invited:
      return 'Invited';
    case UserStatus.Suspended:
      return 'Suspended';
    case UserStatus.Pending:
      return 'Pending';
    default:
      return '-';
  }
};

export const getIncidentStatusChipLabel = (status: IncidentStatus) => {
  switch (status) {
    case IncidentStatus.Completed:
      return 'Completed';
    case IncidentStatus.InProgress:
      return 'In Progress';
    case IncidentStatus.NotStarted:
      return 'Not Started';
    default:
      return '-';
  }
};

export const getQuestionnaireStatusChipLabel = (status: boolean) => {
  switch (status) {
    case true:
      return 'Not submitted';
    case false:
      return 'Submitted';
    default:
      return 'Not submitted';
  }
};

export const getIncidentRoleChipLabel = (role: IncidentRole): string => {
  if (EXCLUDED_ROLES.includes(role)) {
    return '-';
  }
  return IncidentRoleLabelMap[role] || '-';
};

export const getUserRoleLabel = (role: UserRoleNames): string => {
  return UserRoleNamesLabelMap[role] || '-';
};

export const getOrganisationRoles = (
  destination: string
): OrganisationRole[] => {
  switch (destination) {
    case 'insurerOrganisation':
      return [OrganisationRole.Insurer];
    case 'victimOrganisation':
      return [];
    case 'incidentManagerOrganisation':
      return [OrganisationRole.IncidentManager];
    default:
      return [];
  }
};
