import client from '@/api/client';
import {
  GetInitialQuestionnaireData,
  // @ts-ignore
} from '@/api/gql/queries.gql';
import { isUsingFixtures as IS_USING_FIXTURES } from '@/constants/env';
import { RawInitialData } from '../types/initialData';
import { getMockApiInitialData } from '@/__mocks__/api/mockApiInitialData';
import initialTestState from '@/__mocks__/testState/initialTestState';

export const getInitialData = async ({
  questionnaireId,
}: {
  questionnaireId: string;
}): Promise<{
  data?: RawInitialData;
  apiKey?: string;
  error?: any;
}> => {
  const fetchData = async () => {
    if (IS_USING_FIXTURES) {
      return { data: getMockApiInitialData(initialTestState) };
    }

    const { data } = await client.query({
      query: GetInitialQuestionnaireData,
      variables: {
        questionnaireId,
      },
    });

    return {
      data,
    };
  };

  try {
    return await fetchData();
  } catch (error: any) {
    return { error };
  }
};

export default getInitialData;
