import React, { useState, useEffect } from 'react';
import {
  Button,
  InfinateScrollSearch,
  LoadingButton,
} from '@s-rm/react-ui-lib';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectIsCreatingUser,
  setIsOpenCreateUserFormDialog,
  setOrganisationDialogState,
} from '@/store/slices/ui';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { InputLabelRequired, TextField } from '../styled';
import { getIsValid } from '../utils/helpers';
import {
  getOrganisationListItems,
  orgOptionsFormatter,
} from '../utils/organisationSelect';
import {
  selectRecentlyCreatedOrganisation,
  selectUserEntityById,
  setOrgCreationDestination,
} from '@/store/slices/admin/admin';
import { Option } from '@s-rm/react-ui-lib/lib/components/MultiSelectSearch/MultiSelectSearch';
import {
  CreateUserFormCallbackParams,
  defaultFormValues,
  userRoleOptions,
} from './types';
import useSyncMostRecentlyCreatedOrg from '@/hooks/useSyncMostRecentlyCreatedOrg';

export interface CreateUserFormDialog {
  isContributor?: boolean;
  onCreate?: ({
    id,
    name,
    email,
    organisation,
    userRole,
  }: CreateUserFormCallbackParams) => void;
  isEditing?: boolean;
  isDialogOpen: boolean;
  onCancel?: () => void;
  userId?: string;
}

export const CreateUserFormDialog = ({
  isContributor,
  onCreate,
  isDialogOpen,
  onCancel,
  isEditing,
  userId,
}: CreateUserFormDialog) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isUserCreating = useAppSelector(selectIsCreatingUser);
  const user = useAppSelector((state) =>
    selectUserEntityById(state, userId || '')
  );
  const recentlyCreatedOrgs = useAppSelector(selectRecentlyCreatedOrganisation);
  const [isFormValid, setIsFormValid] = useState(false);

  const [formValues, setFormValues] = useState(defaultFormValues);

  const handleInputChange = (name: string, value: any) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleOnClickCancel = () => {
    !isEditing && setFormValues(defaultFormValues);
    onCancel?.();
    dispatch(setIsOpenCreateUserFormDialog(false));
  };

  const handleOnClickCreate = async () => {
    const {
      organisation: { value: orgId, label: orgName },
      email,
      name,
      userRole,
    } = formValues;

    if (!isFormValid && !isEditing) return;

    const userPayload = {
      ...(isEditing ? { id: userId } : {}),
      name,
      email,
      userRole,
      ...(!orgId ? {} : { organisation: { id: orgId, name: orgName } }),
    };

    onCreate?.(userPayload as CreateUserFormCallbackParams);
    dispatch(setIsOpenCreateUserFormDialog(false));
    setFormValues(defaultFormValues);
  };

  const handleOpenNewOrganisationDialog = (destinationField?: string) => {
    dispatch(setOrgCreationDestination(destinationField || ''));
    dispatch(
      setOrganisationDialogState({
        isEditMode: false,
        isOpen: true,
        organisationId: '',
      })
    );
  };

  useEffect(() => {
    const requiredFields = [
      'email',
      'organisation',
      'name',
      ...(!isContributor ? ['userRole'] : []),
    ];
    const isValid = getIsValid(formValues, requiredFields);
    setIsFormValid(isValid);
  }, [formValues, isContributor]);

  useEffect(() => {
    if (user) {
      setFormValues({
        organisation: {
          value: user.organisation?.id ?? '',
          label: user.organisation?.name ?? '',
          secondaryLabel: '',
        },
        email: user.email || '',
        name: user.name || '',
        userRole: user?.userRole || [],
      });
    }
  }, [user]);

  const watchedFields = ['organisation'];

  useSyncMostRecentlyCreatedOrg(
    recentlyCreatedOrgs,
    handleInputChange,
    watchedFields
  );

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleOnClickCancel}
      maxWidth='sm'
      fullWidth
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 3,
        }}
      >
        <Typography
          variant='h3'
          sx={{ fontWeight: 700 }}
          data-testid={`create-user-title`}
        >
          {isContributor && 'Create new contributor'}
          {!isContributor && isEditing && 'Edit user'}
          {!isContributor && !isEditing && 'Create new user'}
        </Typography>
        <IconButton aria-label='close' onClick={handleOnClickCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box>
          <InputLabelRequired shrink htmlFor='email' required={!isEditing}>
            Organisation
          </InputLabelRequired>
          <InfinateScrollSearch
            fetchItems={getOrganisationListItems}
            optionsFormatter={orgOptionsFormatter}
            paginationLimit={10}
            onChange={(value) => handleInputChange('organisation', value)}
            value={formValues.organisation as Option}
            renderValue={(selected) => {
              return (
                <>{(selected as Option)?.label || 'Select an organisation'}</>
              );
            }}
            footerAction={() => handleOpenNewOrganisationDialog('organisation')}
          />
        </Box>

        <FormControl variant='standard' fullWidth sx={{ mt: 2 }}>
          <InputLabelRequired shrink htmlFor='email' required={!isEditing}>
            Email
          </InputLabelRequired>
          <TextField
            size='small'
            required
            margin='dense'
            id='email'
            name='email'
            type='email'
            fullWidth
            placeholder='Enter email'
            disabled={isEditing}
            value={formValues.email}
            onChange={(e) => handleInputChange('email', e.target.value)}
          />
        </FormControl>

        <FormControl variant='standard' fullWidth sx={{ mt: 1 }}>
          <InputLabelRequired shrink htmlFor='name' required={!isEditing}>
            Name
          </InputLabelRequired>
          <TextField
            size='small'
            required
            margin='dense'
            id='name'
            name='name'
            type='text'
            fullWidth
            placeholder='Enter name'
            onChange={(e) => handleInputChange('name', e.target.value)}
            value={formValues.name}
          />
        </FormControl>
        {!isContributor && (
          <Box sx={{ mt: 1 }}>
            <InputLabelRequired shrink htmlFor='name' required={!isEditing}>
              Role
            </InputLabelRequired>
            <Select
              data-testid={'select-contrib-role'}
              fullWidth
              size='small'
              multiple
              name='userRole'
              value={formValues.userRole}
              onChange={(event) =>
                handleInputChange(
                  'userRole',
                  typeof event.target.value === 'string'
                    ? event.target.value.split(',')
                    : event.target.value
                )
              }
              displayEmpty
              renderValue={(selected) =>
                selected.length
                  ? userRoleOptions
                      .filter((option) => selected.includes(option.value))
                      .map((option) => option.label)
                      .join(', ')
                  : 'Select a user role'
              }
            >
              {userRoleOptions.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  <ListItemText primary={label} />
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
      </DialogContent>

      <DialogActions
        sx={{
          padding: 2,
          borderTop: `1px solid ${theme.palette.grey[300]}`,
        }}
      >
        <Button onClick={handleOnClickCancel} variant='outlined'>
          Cancel
        </Button>
        <LoadingButton
          onClick={handleOnClickCreate}
          variant='contained'
          loading={isUserCreating}
          disabled={!isFormValid && !isEditing}
          data-testid='confirmation-modal-confirm-btn'
        >
          {isEditing ? 'Save' : 'Create'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
