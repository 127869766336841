import { useRouter } from 'next/router';
import { SidebarNavigation } from '@s-rm/react-ui-lib';
import { StyledListSubheader } from './styled';
import { Box, IconButton } from '@mui/material';
import logo from '../../assets/img/polus-response.png';
import { sidebarNavigationData } from './data';
import Image from 'next/image';
import { getRootPathFromRouter } from '@/utils/router';
import useHasAuthorization from '@/hooks/useHasAuthorization';
import { AccessPermissions } from '@/store/types/config';
import { getFilteredNavigation } from './getFilteredNavigation';

const Sidebar = () => {
  const router = useRouter();
  const path = getRootPathFromRouter(router);
  const handleClick = (to: string) => {
    router.push(to);
  };

  const navigationData = getFilteredNavigation(sidebarNavigationData, {
    canViewUsersTab: useHasAuthorization({
      requiredPermissions: [AccessPermissions.AccessUsers],
    }),
    canViewOrganisationsTab: useHasAuthorization({
      requiredPermissions: [AccessPermissions.AccessOrganisations],
    }),
    canViewIncidentsTab: useHasAuthorization({
      requiredPermissions: [AccessPermissions.ViewIncidents],
    }),
  });

  return (
    <SidebarNavigation
      currentPath={`/${path}`}
      navigationData={navigationData}
      onClick={handleClick}
      containerSx={{
        backgroundColor: 'grey.50',
      }}
      subheader={
        <StyledListSubheader>
          <Box py={1}>
            <Image src={logo} alt='asm-logo' height={20} />
          </Box>
        </StyledListSubheader>
      }
    />
  );
};

export default Sidebar;
